import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TaxAuditApiService } from './tax_audit.api.service';

import { TaxAuditCategories } from '../_model/getTaxAuditCategories';
import { TaxAuditDetails } from '../_model/getTaxAuditDetails';

import { Observable, observable } from 'rxjs';


@Injectable()
export class TaxAuditDataService {

  constructor(
    private http: HttpClient,
    private api: TaxAuditApiService    
  ) { }



  getTaxCategories() {
    return this.api.getTaxCategories()    
  }

  getTaxDetails() {
    return this.api.getTaxDetails()    
  }


  getTaxReport001() {
    return this.api.getTaxReport001()    
  }

  getTaxReport002() {
    return this.api.getTaxReport002()    
  }


  createTaxAuditCategory(n_type:number, n_name:string, n_date:string) {
    return this.api.createTaxAuditCategory(n_type, n_name, n_date);    
  }


  createTaxAuditItem(n_guid:string, n_itemType:number, n_amount:string, n_isCredit:number, n_notes:string) {
    return this.api.createTaxAuditItem(n_guid, n_itemType, n_amount, n_isCredit, n_notes);    
  }



}