<ngx-spinner bdOpacity = 0.9 bdColor = "#fff" size = "medium" color = "#6495ED" type = "ball-spin-clockwise" [fullScreen] = "false"><p style="color:#6495ED;padding-top:120px;" > Loading... </p></ngx-spinner>


<div class="modal-header modal-custom-hdr">
    <div class="modal-title pull-left">Add Bank Account</div>    
</div>
<div class="modal-body">




	<!-- ACCOUNT TYPE -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field>
			  <mat-label>Institution Type</mat-label>
  			  <select matNativeControl required [formControl]="f_Institution">
  			    <option label="-- Select Type --"></option>
  			    <option *ngFor="let z of dictionaryObj | dictionaryfilter:200; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
  			  </select>
		    </mat-form-field>
    	</div>
    </div>



	<!-- COUNTRY -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field>
			  <mat-label>Country</mat-label>
  			  <select matNativeControl required [formControl]="f_Country">
  			    <option label="-- Select Country --"></option>
  			    <option value="CA" >Canada</option>
  			    <option value="US" >United States</option>
  			  </select>
		    </mat-form-field>
    	</div>
    </div>



    <!-- BANK NAME -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field [style.width.px]=350>
			  <mat-label>Bank Name</mat-label>
  			  <select matNativeControl required [formControl]="f_BankGuid">
  			    <option label="-- Bank Name --"></option>
  			    <option *ngFor="let z of bankObj" value="{{z.guid}}" >{{z.reporting_institution}}</option>
  			  </select>
		    </mat-form-field>
    	</div>
    </div>







    <!-- CURRENCY and ACCOUNT TYPE -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field>
			  <mat-label>Currency</mat-label>
  			  <select matNativeControl required [formControl]="f_Currency">
  			    <option label="-- Select Currency --"></option>
  			    <option value="CAD" >Canadian - CAD</option>
  			    <option value="USD" >US Dollar - USD</option>
  			  </select>
		    </mat-form-field>
    	</div>

    	<div class="col-3">
    		<mat-form-field>
			  <mat-label>Account Type</mat-label>
  			  <select matNativeControl required [formControl]="f_AccountType">
  			    <option label="-- Select Type --"></option>
  			    <option *ngFor="let z of dictionaryObj | dictionaryfilter:100; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
  			  </select>
		    </mat-form-field>
    	</div>
    </div>



    <!-- User Label -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-8">
    		<mat-form-field appearance="fill" [style.width.px]=350>
    		  <mat-label>User Label</mat-label>
	          <input matInput placeholder="" [formControl]="f_UserLabel" maxlength="100" placeholder="" required>
	          <mat-error *ngIf="f_UserLabel.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    </div>


    <!-- ACCOUNT ID AND NUMBER -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=250>
    		  <mat-label>Account ID</mat-label>
	          <input matInput placeholder="" [formControl]="f_AccountId" maxlength="100" placeholder="" required>
	          <mat-error *ngIf="f_AccountId.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=250>
    		  <mat-label>Account Number</mat-label>
	          <input matInput placeholder="" [formControl]="f_AccountNumber" maxlength="100" placeholder="" required>
	          <mat-error *ngIf="f_AccountNumber.invalid">{{error_vendor_name}}</mat-error>
	          <!--
	          <mat-hint>50 Characters</mat-hint>
	          -->
	        </mat-form-field>
    	</div>
    </div>



    <br/>
    <br/>
    <br/>
    <span>additional info</span>
    <hr/>


    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Branch Id</mat-label>
	          <input matInput placeholder="" [formControl]="f_Branch" maxlength="30" placeholder="" required>
	          <mat-error *ngIf="f_Branch.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Routing Number</mat-label>	
	          <input matInput placeholder="" [formControl]="f_Routing" maxlength="30" placeholder="" required>
	          <mat-error *ngIf="f_Routing.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Swift Code</mat-label>	
	          <input matInput placeholder="" [formControl]="f_SwiftCode" maxlength="30" placeholder="" required>
	          <mat-error *ngIf="f_SwiftCode.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    </div>


	<div class="row">
      <div class="col-12" style="padding-top:25px;font-size:20px;text-align:right;">
        <button type="button" class="btn btn-light" style="color:#909090;" (click)="bsModalRef.hide()">Cancel</button>  &nbsp; &nbsp;
        <button  type="button" class="btn btn-info" (click)="onSubmit()">Submit</button>
        <!--
        	<button type="button" class="btn btn-info" (click)="createInvoice()">Next</button>
    	-->
      </div>      
    </div>  	
</div>


