import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import { TokenManager } from '../_serviceAPI/token-manager.service';

import { TokenManager } from '../_service/token-manager.service';

import { Observable } from 'rxjs';

// import { DeviceDetectorService } from 'ngx-device-detector';
import { Dictionary } from '../_model/dictionary';

import { BankList } from '../_model/getBankList';

import { AccountSummary } from '../_model/getAccountSummary';


import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;


@Injectable()
export class UserApiService {

  deviceInfo:string = "";

  headersVendor = this.tokenManager.getTokenVendorString()

  // n_env  = "https://api.streams.cc"
  // n_env  = "http://localhost:8080"

  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) {
      // this.epicFunction()
    }


  /* Pre-Registration */

  public pre_registration(n_email_address:string) {
    {
        return this.http
        .post(API_URL + '/v01/CREATE/pre_registration', {"associated_email":n_email_address,"collection_name":"Global","app_name":"BANKWISE.APP","registration_stage":"PRE-REGISTRATION","transaction_guid":"b66ce55f-6702-41db-bbf8-d4f6778ba54b","appsource":"web"} , { headers: this.tokenManager.getTokenVendorWithUser()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          // return events.map((e: any) => new Brand(e));
          }),
          catchError(this.handleError)
        )                   
    }    
  }



  public userLogin(n_email_address:string, n_password:string): Observable<any> {
    {  
        const body = {"email_address":n_email_address, "password":n_password, "authentication_type":1}
        return this.http
        .post(API_URL + '/v01/authenticate_user', body , {'headers':this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }




  public getDictionary(): Observable<Dictionary> {
    {
        return this.http
        .post(API_URL + '/v01/GET/dictionary', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {return res;}),
          catchError(this.handleError)
          )
    }    
  }



  public getBanks(): Observable<BankList> {
    {
        return this.http
        .post(API_URL + '/v01/GET/banks', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {return res;}),
          catchError(this.handleError)
          )
    }    
  }


  public getCurrencies() {
    {
        return this.http
        .post(API_URL + '/v01/GET/currencies', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          // return events.map((e: any) => new Brand(e));
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}