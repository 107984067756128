<ngx-spinner bdOpacity = 0.9 bdColor = "#fff" size = "medium" color = "#6495ED" type = "ball-spin-clockwise" [fullScreen] = "false"><p style="color:#6495ED;padding-top:120px;" > Loading... </p></ngx-spinner>


<div class="modal-header modal-custom-hdr">
    <div class="modal-title pull-left">Create Payment Item</div>    
</div>
<div class="modal-body">


	<!-- VENDOR -->

	<h1>Expense</h1>
	
	<div class="row" style="margin-bottom:50px;">
		<div class="col-12">
			<table>
				<tr>
					<th class="hdr_row">Source</th>
					<th class="hdr_row">Category</th>
					<th class="hdr_row">Item Type</th>
					<th class="hdr_row">Amount Owing</th>
					<th class="hdr_row">Payment Date</th>
				</tr>
				<tr>	
					<td>{{data.source}}</td>
					<td>{{data.category}}</td>
					<td>{{data.item_type}}</td>
					<td>{{data.amount_to_be_paid}}</td>
					<td>{{data.next_date}}</td>
				</tr>
			</table>			
		</div>
	</div>



    <div class="row">
       	<div class="col-4">
       		<mat-form-field appearance="fill" [style.width.px]=250>
       		  <mat-label>Payment Amount</mat-label>
	          <input matInput placeholder="" [formControl]="f_amount" maxlength="50" placeholder=""required>
	          <mat-error *ngIf="f_amount.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
       	</div>

       	<div class="col-4">
    		<mat-form-field>
			  <mat-label>Currency Type</mat-label>
  			  <select matNativeControl required [formControl]="f_currency">
  			    <option label="-- Currency --"></option>
  			    <option *ngFor="let z of dictionaryObj | dictionaryfilter:600; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
  			  </select>
		    </mat-form-field>
       	</div>
    </div>


    <div class="row" style="margin-top:10px;">
    	<div class="col-4">
		    <mat-form-field [style.width.px]=480>
				  <mat-label>Select Payment Source</mat-label>
				  <select matNativeControl required [formControl]="f_source">
				    <option *ngFor="let z of paymentSrcObj; let i = index" value="{{z.guid}}">{{z.payment_type_description}}  |  {{z.payment_source_name}}</option>
				  </select>
			</mat-form-field>
		</div>
	</div>


	<!-- Date -->
    <div class="row">
       	<div class="col-4">
       		<mat-form-field appearance="fill" [style.width.px]=250>
       		  <mat-label>Payment Date</mat-label>
	          <input matInput placeholder="" [formControl]="f_date" maxlength="50" placeholder=""required>
	          <mat-error *ngIf="f_date.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
       	</div>
    </div>



	<div class="row">
      <div class="col-12" style="padding-top:25px;font-size:20px;text-align:right;">
        <button type="button" class="btn btn-light" style="color:#909090;" (click)="bsModalRef.hide()">Cancel</button>  &nbsp; &nbsp;
        <button  type="button" class="btn btn-info" (click)="onSubmit()">Next</button>
        <!--
        	<button type="button" class="btn btn-info" (click)="createInvoice()">Next</button>
    	-->
      </div>      
    </div>  	
</div>


