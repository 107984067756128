<p class="crud_title">Budget Calendar</p>
<br/>

<p class="crud_sub_title">This includes:<br/>
	{{n_report_description}}
</p>

<br/>

<button type="button" class="btn btn-info btn-sm" (click)="openCreateBudgetItemModal()">Create Item</button>

<hr/>




<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
</mat-form-field>

<div style="float:right;">
	<span class="report-totals">Amt Due: ${{ calculation()[1] }}</span>
	&nbsp; &nbsp;
	<span class="report-totals">Outstanding Balance: ${{ calculation()[0] }}</span>
</div>


<!-- Data Table -->
		<div class="col-sm-12 col-md-12 col-lg-12" class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)" class="">
			  <!--- Note that these columns can be defined in any order.
			        The actual rendered columns are set as a property on the row definition" -->

			  <!-- User Label -->
			  <ng-container matColumnDef="vendor_name">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Vendor </th>
			    <td mat-cell *matCellDef="let element"> {{element.vendor_name}} </td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="product_category_type_description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Category </th>
			    <td mat-cell *matCellDef="let element"> {{element.product_category_type_description}} </td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="amount_due">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Budget Amt</th>
			    <td mat-cell *matCellDef="let element"> ${{element.amount_due}} </td>
			  </ng-container>

			  <!-- User Label -->
			  <ng-container matColumnDef="frequency_type_description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Frequency</th>
			    <td mat-cell *matCellDef="let element"> {{element.frequency_type_description}} </td>
			  </ng-container>

			  <!-- User Label -->
			  <ng-container matColumnDef="frequency_due_date">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Due Date</th>
			    <td mat-cell *matCellDef="let element"> {{element.frequency_due_date}} </td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="expense_type_description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Expense Type</th>
			    <td mat-cell *matCellDef="let element"> {{element.expense_type_description}} </td>
			  </ng-container>

			  <!-- User Label -->
			  <ng-container matColumnDef="account_info">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Account Info</th>
			    <td mat-cell *matCellDef="let element"> {{element.account_info}}  </td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="outstanding_balance">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Outstanding </th>
			    <td mat-cell *matCellDef="let element"> ${{element.outstanding_balance}} </td>
			  </ng-container>


			  <ng-container matColumnDef="default_payment_src">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Payment Src (default) </th>
			    <td mat-cell *matCellDef="let element"> {{element.default_payment_src}} </td>
			  </ng-container>



			  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			  <!-- Empty State -->
			  <!-- Row shown when there is no data. -->
		      <tr class="mat-row" *matNoDataRow>
		          <td *ngIf="is_api_active==true" class="mat-cell" [attr.colspan]="4">
		          	<span>Loading...</span>
		          	<mat-progress-bar mode="indeterminate" class="table-progressbar"></mat-progress-bar>
		          </td>
		          <td *ngIf="is_api_active==false" class="mat-cell" [attr.colspan]="4">
		          	<div style="padding-top:40px">** No data found</div>
		      	  </td>
		      </tr>

			</table>

			<mat-paginator [length]="100"
              [pageSize]="25"
              showFirstLastButtons
              [pageSizeOptions]="[25, 50, 100]">
</mat-paginator>

		</div>


<div style="margin-top:50px;margin-bottom:10px;">
	<span class="budget_title">Monthly Budget Notes</span>
</div>
<div>
	<ul>
		<li>Virgin $65 dollars should be paid (Oct)</li>
		<li>Fido - $351.00 (pay 189.00 now)</li>
		<li>SunLife - Change Beneficiary</li>
		<li>House Insurance for Ontario ????</li>
		<li>Amazon AWS - 93.00 September 3rd</li> 
		<li>Heroku - 195.00 USD September Bill Declined</li> 
	</ul>
</div>




