import { Injectable, Inject } from '@angular/core';
// import { Subject }    from 'rxjs/Subject';
import { Subject }    from 'rxjs';

@Injectable()
export class CommonService {
 
  private notify = new Subject<any>();
  
  /**
   * Observable string streams
  */
  
  notifyObservable$ = this.notify.asObservable();

  constructor(){}

  public notifyOther(data: any) {
    console.log('its common here');
    console.log(data);
    
    if (data) {
      this.notify.next(data);
    }
  }
  
}