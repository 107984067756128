	<div style="padding-left: 30px">

		<div class="flex-container" style="margin-top:20px;margin-bottom:10px;margin-left:0px;">
		  <div class="flex-child-not-used" style="padding-top:6px;margin-bottom:25px;color:#000;font-weight:bold;">
		    BankWise
		  </div>		  
		</div>


	<ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    
                    <li class="nav-item" style="padding-left:10px;">
                            <a href="/main/dashboard" class="nav-link align-middle px-0 mnuref" [ngClass]="{'active_mnu': n_url=='dashboard'}">
                                <i class="fs-4 bi-pie-chart icon-yellow" style="font-size:16px;"></i>
                                <span class="ms-1 d-none d-sm-inline icon-yellow"  [ngClass]="{'active_mnu': n_url=='dashboard'}" style="font-size:18px;font-weight:150;">&nbsp; Dashboard</span>
                            </a>

                            <!--
                            <ul class="nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            
                            
                                <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                    <a href="/main/company" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='company'}">
                                        <i class="bi bi-arrow-return-right"></i>
                                        <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='company'}" style="font-size:16px;font-weight:150;">&nbsp; Company</span>
                                    </a>
                                </li>


                                <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                    <a href="/main/brand" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='brand'}">
                                        <i class="bi bi-arrow-return-right"></i>
                                        <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='brand'}" style="font-size:16px;font-weight:150;">&nbsp; Brand</span>
                                    </a>
                                </li>

                            </ul>
                            -->

                    </li>




                    <li class="nav-item" style="margin-top:12px;padding-left:10px;">
                        

                        <a data-bs-toggle="collapse" class="nav-link px-0 align-middle mnuref-static">
                            <i class="fs-4 bi-journal-text icon-yellow" style="font-size:18px;"></i> <span class="ms-1 d-none d-sm-inline icon-yellow" style="color:#C0C0C0;font-size:18px;font-weight:100;">&nbsp; Accounts</span>
                        </a>

                        <ul class="nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            
                            <!--
                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/account" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='account'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='account'}" style="font-size:16px;font-weight:150;">&nbsp; Accounts</span>
                                </a>
                            </li>
                            -->



                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/banks" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='banks'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='banks'}" style="font-size:16px;font-weight:150;">&nbsp;Banks</span>
                                </a>
                            </li>

                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/credit" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='credit'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='credit'}" style="font-size:16px;font-weight:150;">&nbsp; Credit</span>
                                </a>
                            </li>


                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/investments" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='investments'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='investments'}" style="font-size:16px;font-weight:150;">&nbsp; Investments</span>
                                </a>
                            </li>


                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/assets" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='assets'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='assets'}" style="font-size:16px;font-weight:150;">&nbsp; Assets</span>
                                </a>
                            </li>


                        </ul>

                    </li>







                    <li class="nav-item" style="margin-top:12px;padding-left:10px;">
                        
                        <a data-bs-toggle="collapse" class="nav-link px-0 align-middle mnuref-static">
                            <i class="fs-4 bi-journal-text icon-yellow" style="font-size:18px;"></i> <span class="ms-1 d-none d-sm-inline icon-yellow" style="color:#C0C0C0;font-size:18px;font-weight:100;">&nbsp; Payment</span>
                        </a>

                        <ul class="nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            
                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/payment-source" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='payment-source'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='payment-source'}" style="font-size:16px;font-weight:150;">&nbsp; Sources</span>
                                </a>
                            </li>                                               
                            
                        </ul>

                    </li>



                    <li class="nav-item" style="margin-top:12px;padding-left:10px;">
                        
                        <a data-bs-toggle="collapse" class="nav-link px-0 align-middle mnuref-static">
                            <i class="fs-4 bi-journal-text icon-yellow" style="font-size:18px;"></i> <span class="ms-1 d-none d-sm-inline icon-yellow" style="color:#C0C0C0;font-size:18px;font-weight:100;">&nbsp; Expenses</span>
                        </a>

                        <ul class="nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            

                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/budget-items/monthly" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='budget-items' && n_url_filter=='monthly'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='budget-items/monthly'}" style="font-size:16px;font-weight:150;">&nbsp; Monthly</span>
                                </a>
                            </li>


                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/budget-items/annual" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='budget-items' && n_url_filter=='annual'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='budget-items/annual'}" style="font-size:16px;font-weight:150;">&nbsp; Annual</span>
                                </a>
                            </li>


                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/debt-management" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='budget-items' && n_url_filter=='debt-management'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='debt-management'}" style="font-size:16px;font-weight:150;">&nbsp; Open Debt</span>
                                </a>
                            </li>
                                                       
                            
                        </ul>

                    </li>




                    








                    





                    <li class="nav-item" style="margin-top:12px;padding-left:10px;">
                        

                        <a data-bs-toggle="collapse" class="nav-link px-0 align-middle mnuref-static">
                            <i class="fs-4 bi-journal-text icon-yellow" style="font-size:18px;"></i> <span class="ms-1 d-none d-sm-inline icon-yellow" style="color:#C0C0C0;font-size:18px;font-weight:100;">&nbsp; Reports</span>
                        </a>

                        <ul class="nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">



                        	<li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/invoices" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='invoices'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='invoices'}" style="font-size:16px;font-weight:150;">&nbsp; Invoices</span>
                                </a>
                            </li>


                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/report-income" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='report-income'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='report-income'}" style="font-size:16px;font-weight:150;">&nbsp; Income</span>
                                </a>
                            </li>




                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/projected-budget" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='projected-budget'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='report-budget'}" style="font-size:16px;font-weight:150;">&nbsp; Projected Budget</span>
                                </a>
                            </li>


                            <!--
                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/projected-budget" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='projected-budget'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='report-budget'}" style="font-size:16px;font-weight:150;">&nbsp; Old Budget</span>
                                </a>
                            </li>
                            -->

                            
                            
                            <!--
                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/invoices" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='invoices'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='invoices'}" style="font-size:16px;font-weight:150;">&nbsp; Invoices</span>
                                </a>
                            </li>
                            -->

                            
                        </ul>

                    </li>






                    <li class="nav-item" style="margin-top:12px;padding-left:10px;">
                        

                        <a data-bs-toggle="collapse" class="nav-link px-0 align-middle mnuref-static">
                            <i class="fs-4 bi-journal-text icon-yellow" style="font-size:18px;"></i> <span class="ms-1 d-none d-sm-inline icon-yellow" style="color:#C0C0C0;font-size:18px;font-weight:100;">&nbsp;Tax Audit</span>
                        </a>

                        <ul class="nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                            
                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/tax-audit-categories" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='tax-audit-categories'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='tax-audit-categories'}" style="font-size:14px;font-weight:150;">&nbsp;Categories</span>
                                </a>
                            </li>

                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/tax-audit-items" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='tax-audit-items'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='tax-audit-items'}" style="font-size:14px;font-weight:150;">&nbsp;Items</span>
                                </a>
                            </li>


                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/tax-audit-report-a" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='tax-audit-report-a'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='tax-audit-report-a'}" style="font-size:14px;font-weight:150;">&nbsp;Report-A</span>
                                </a>
                            </li>


                            <li class="w-100" style="padding-left:30px;margin-top:8px;">
                                <a href="/main/tax-audit-report-002" class="nav-link px-0 mnuref" [ngClass]="{'active_mnu': n_url=='tax-audit-report-002'}">
                                    <i class="bi bi-arrow-return-right"></i>
                                    <span class="d-none d-sm-inline icon-yellow" [ngClass]="{'active_mnu': n_url=='tax-audit-report-002'}" style="font-size:14px;font-weight:150;">&nbsp;Report-002</span>
                                </a>
                            </li>



                        </ul>

                    </li>


                </ul>

                <div style="color:#000;margin-top:120px;padding-left:10px;">
                	<i class="fs-4 bi bi-box-arrow-right" style="font-size:14px;color:#708090;"></i> 
                	<span style="color:#708090;">&nbsp; &nbsp; log out</span>
                </div>


</div>





