export class PaymentSourceOptions {

  guid!:string;
  user_guid!:string;
  
  payment_type!:number;

  payment_source_name!:string;
  payment_type_description!:string;

  account_guid!:string;
  internal_user_label!:string;
  
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}