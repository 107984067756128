<p class="crud_title">Projected Budget (Expense and Income)</p>
<br/>

<p class="crud_sub_title">This includes:<br/>
Projected Income as well as expenses for Specific Time Periods
</p>
<br/>

<button type="button" class="btn btn-info btn-sm">Submit</button>

<div style="margin-top:15px;">
	<mat-radio-group aria-label="Select an option" [(ngModel)]="n_show_only_inscope">
	  <mat-radio-button value="1" [checked]="true"><span class="small_radio_label">Hide OutScope</span></mat-radio-button>
	  &nbsp; &nbsp;
	  <mat-radio-button value="0"><span class="small_radio_label">Show OutScope</span></mat-radio-button>
	</mat-radio-group>
</div>
<hr/>


<div class="row" style="margin-bottom:25px;">
    <div class="col col-1 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Type</span>
    </div>
    <div class="col col-2 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Vendor/Source</span>
    </div>
    <div class="col col-1 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Category</span>
    </div>
    <div class="col col-1 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Item Type</span>
    </div>
    <div class="col col-1 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Amount</span>
    </div>
    <div class="col col-1 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Freq</span>
    </div>
    <div class="col col-1 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Date</span>
    </div>
    <div class="col col-1 align-items-left" style="border:1px solid #000">
      <span class="hdr_item">Payment</span>
    </div>
</div>



<div *ngFor="let z of projectedBudget; let i = index">

		<div class="row income_row">
			<div class="col col-1 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">{{z.income.type}}</span>
		    </div>
		    <div class="col col-2 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">{{z.income.source}}</span>
		    </div>
		    <div class="col col-1 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">{{z.income.category}}</span>
		    </div>
		    <div class="col col-1 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">{{z.income.item_type}}</span>
		    </div>
		    <div class="col col-1 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">{{z.income.amount_to_be_paid}}</span>
		    </div>
		    <div class="col col-1 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">{{z.income.frequency}}</span>
		    </div>
		    <div class="col col-1 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">{{z.time_period}}</span>
		    </div>
		    <div class="col col-1 align-items-left" style="border:0px solid #000">
		      <span class="hdr_detail_item">&nbsp;</span>
		    </div>

		</div>


		<div *ngFor="let x of z.expenses; let y = index" >
				
				<div *ngIf="x.expense_inscope == true && n_show_only_inscope == 1 || n_show_only_inscope == 0" class="row">
					<div class="col col-1 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span class="detail_item">{{x.type}}</span>
				    </div>
				    <div class="col col-2 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span class="detail_item">{{x.source}}</span>
				    </div>
				    <div class="col col-1 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span class="detail_item">{{x.category}}</span>
				    </div>
				    <div class="col col-1 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span class="detail_item">{{x.item_type}}</span>
				    </div>
				    <div class="col col-1 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span class="detail_item">{{x.amount_to_be_paid}}</span>
				    </div>
				    <div class="col col-1 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span class="detail_item">{{x.frequency}}</span>
				    </div>
				    <div class="col col-1 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span class="detail_item">{{x.next_date}}</span>
				    </div>
				    <div class="col col-1 align-items-left" [ngClass]="{'is_inscope': x.expense_inscope == false}">
				      <span *ngIf="x.payment_made == true" class="detail_item">{{x.payment_amount_paid}}</span>
				      <span *ngIf="x.payment_made == false" class="detail_item">
				      	<div (click)="addPayment(x.guid, x.income_guid, x)" style="font-size:20px;" >...</div>
				      </span>
				    </div>			
				</div>

		</div>


		<br/>

		<div class="row">
			<div class="col col-3 align-items-left">
		      <span class="line_totals">Total Inscope:&nbsp;&nbsp; </span>
		      <span class="line_totals" style="color:blue;font-weight:bold;">${{z.totals.total_inscope}}</span>
		      
		      <br/>
		      	<span class="line_totals">Personal:&nbsp;&nbsp; </span>
		      	<span class="line_totals" style="color:grey;font-weight:bold;font-size:10px">${{z.totals.total_inscope_personal}}</span>
		      &nbsp; &nbsp;
		      	<span class="line_totals">Business:&nbsp;&nbsp; </span>
		      	<span class="line_totals" style="color:grey;font-weight:bold;font-size:10px">${{z.totals.total_inscope_business}}</span>
		    </div>


		    <div class="col col-3 align-items-left">
		      <span class="line_totals">Remainder:&nbsp;&nbsp; </span>
		      	<span class="line_totals" style="color:red;font-weight:bold;">${{z.totals.total_inscope_vs_income}}</span>
		    </div>

		    <div class="col col-3 align-items-left">
		      <span class="line_totals">Total Outscope:&nbsp;&nbsp; </span>
		      	<span class="line_totals" style="color:purple;font-weight:bold;">${{z.totals.total_outscope}}</span>
		    </div>

		</div>


		<br/>
		<br/>
		<br/>
		<br/>


</div>


