import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_service/auth.guard';
import { ToastrModule } from 'ngx-toastr';


import { WaitComponent } from './landing/wait/wait.component';
import { NotFoundComponent } from './landing/not-found/not-found.component';

import { LoginComponent } from './landing/login/login.component';

// Main
import { MainComponent } from './main/main.component';

// Share
import { ShareComponent } from './share/share.component';


const routes: Routes = [


  { path: '', component: WaitComponent},
  { path: 'login', component: LoginComponent},

  { path: 'main/:menu_option', component: MainComponent, canActivate: [AuthGuard]},
  { path: 'main/:menu_option/:menu_filter', component: MainComponent, canActivate: [AuthGuard]},    
  { path: 'main', redirectTo: 'main/dashboard'},

  { path: 'share/:menu_option', component: ShareComponent},
  { path: 'share/:menu_option/:menu_filter', component: ShareComponent},
  
  /*
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'forgot', component: ForgotComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'privacy', component: PrivacyComponent},
  */

  { path: '404', component: NotFoundComponent},
  { path: '**', redirectTo: '404'}

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
