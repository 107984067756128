import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceApiService } from './resource.api.service';
import { ResourceModel } from '../_model/resource';

@Injectable()
export class ResourceDataService {

  constructor(
    private http: HttpClient,
    private api: ResourceApiService    
  ) { }


  getAllResources() {
    return this.api.getAllResources()
  }


  getResource(n_resource_guid:string) {
    return this.api.getResource(n_resource_guid)
  }

  deleteResource(n_resource_guid:string, n_delete_status:number) {
    return this.api.deleteResource(n_resource_guid, n_delete_status)
  }

  createResource(re:ResourceModel): Observable<ResourceModel> {
    return this.api.createResource(re)
  }

  updateResource(n_resource_guid:string, n_attr_name:string, n_attr_value:string) {
    return this.api.updateResource(n_resource_guid, n_attr_name, n_attr_value)
  }

}