import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, HostListener, Renderer2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Router} from "@angular/router";

/* Material */
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatSnackBarModule, MatSnackBar} from '@angular/material/snack-bar';

/* Service */
import { UserDataService } from '../../_serviceAPI/user.data.service';
import { TokenManager } from '../../_service/token-manager.service';

/* Model */
import { MsgInfo } from '../../_model/msginfo';


export interface DialogData {  
  actionType:string;
  securitypin: string;
}



@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.css']
})
export class WaitComponent implements OnInit {


  n_year:number = 0;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.n_year = new Date().getFullYear();
  }

  openDialog(): void {

          // Determine Action Type Based on (radio dialog)        
            const dialogRef = this.dialog.open(NotifyDialog, {
              panelClass: 'custom-dialog-container',
              disableClose: true,
              width: '400px',
              data: {actionType:"data"}
            });

            dialogRef.afterClosed().subscribe(result => {
              // this.securitypin = result;
            });
        }

}





@Component({
  selector: 'modal-notify',
  templateUrl: 'modal-notify.html',
  styleUrls: ['./wait.component.css']
})
export class NotifyDialog {


        msg_info!: MsgInfo;

  
        email = new FormControl('');
        error_msg!:string;


        in_progress:boolean = false;

        constructor(    
              private http: HttpClient,
              private userService:UserDataService,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<NotifyDialog>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData
          ) {
        }


        ngOnInit(): void {
              this.in_progress = false;
        }
        


        // Submit Notify
        submitNotify() {

          this.error_msg = "";

          // validate email
          if (!this.validEmail(this.email.value)) {
              this.email.setErrors({'invalid email': true});
              this.error_msg = "invalid email";
              return;
          }    

          const _this = this;
          this.in_progress = true;

          this.userService.pre_registration(this.email.value)
                    .subscribe(
                        (data:any) => {

                            if (data['status'][0]['msg_status'] != "success") {

                              // Show Error
                              _this.in_progress = false;

                              this.msg_info = data['status'][0]['msg_data']['msg_info'][0];

                              this.email.setErrors({'invalid email': true});
                              this.error_msg = this.msg_info.description_text;

                              this.openSnackBar(this.msg_info.description_text, "close")

                            } else {

                              // Show Success
                              _this.in_progress = false;

                              this.msg_info = data['status'][0]['msg_data']['msg_info'][0];

                              this.openSnackBar(this.msg_info.description_text, "close")

                            }
                        },
                        (error:any)=> {
                            _this.in_progress = false;
                            alert("ERROR - " + "a network error occurred");
                        });
          }

        
        // Validates email address of course.
        validEmail(e:string) {
            var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
            return String(e).search (filter) != -1;
        }


        openSnackBar(message: string, action: string) {
          this._snackBar.open(message, action, {duration:2000});
        }


        // Close Dialog
        closeDialog(): void {
          this.dialogRef.close();
        }


}

