export class submitBudgetItem {

  vendor_guid!:string;
  vendor_name!:string;

  currency_code!:string;
  category_product_type!:number;
  expense_type!:number;

  account_info!:string;
  account_notes!:string;
  outstanding_balance!:string;

  amount_due!:string;
  amount_due_conv!:string;

  frequency_type!:number;

  frequency_start_date!:string;
  frequency_end_date!:string;

  weekly_due_date!:number;
  month_due_date!:number;
  annual_due_date!:string;

  payment_src!:string;

  annual_month_due!:number;
  annual_day_due!:number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}