<ngx-spinner bdOpacity = 0.9 bdColor = "#fff" size = "medium" color = "#6495ED" type = "ball-spin-clockwise" [fullScreen] = "false"><p style="color:#6495ED;padding-top:120px;" > Loading... </p></ngx-spinner>





<section class="vh-100" style="background-color: #fff;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">

            <div class="col-md-6 col-lg-5 d-none d-md-block" style="padding-top:70px;padding-left:30px;">
              
              <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                alt="login form" class="img-fluid" style="border-radius: 1rem 0 0 1rem;height:90%;" />
            </div>
            <div class="col-md-6 col-lg-7 d-flex align-items-center">
              <div class="card-body p-4 p-lg-5 text-black">

                <form>

                  <div class="d-flex align-items-center mb-3 pb-1">
                    
                    <a class="navbar-brand" routerLink="/" style="font-size:18px;font-weight:bold;">
                    	<span class="logo-icon">
                    		<img class ="logo_display" src="../assets/images/logo/logo_32.png" />		
			            </span>
            		BankWise.app <span style="font-size:11px;color:#FF0000;">&nbsp;(private beta)</span></a> 
                                        
                  </div>

                  <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Sign into your account</h5>

                  <div class="form-outline mb-4">
                    <div class="row">
                      <div class="col-md-10">
                        <input type="email" id="form3Example3c" class="form-control" [formControl]="user_email_address" maxlength="150" required/>
                      </div>
                      <div class="col-md-2">
                        &nbsp;
                      </div>
                    </div>                    
                    <label class="form-label" for="form2Example17">Email address</label>
                  </div>

                  <div class="form-outline mb-4">
                        <div class="row">
                          <div class="col-md-10">
                            <input type="{{pwrd_type}}" id="form3Example3c" class="form-control" [formControl]="user_password" maxlength="25" required/> 
                          </div>
                          <div class="col-md-2">
                            <mat-icon (click)="toggleShow()">remove_red_eye</mat-icon>
                          </div>
                        </div>
                        <label class="form-label" for="form2Example27">Password</label>
                    </div>

                  <div class="pt-1 mb-4">
                    <button class="btn btn-dark btn-lg btn-block" type="button" (click)="user_login()">Login</button>
                  </div>


                  <a class="small text-muted" routerLink="/forgot">Forgot password?</a>
                  <br/>
                  <br/>
                  
                  <p class="mb-5 pb-lg-2" style="color: #393f81;">Don't have an account? <a href="#!"
                      style="color: #393f81;" routerLink="/pre-register">Register here</a></p>
                  <a routerLink="/terms" target="_blank" class="small text-muted">Terms of use.</a> &nbsp; &nbsp;
                  <a routerLink="/privacy" target="_blank" class="small text-muted">Privacy policy</a>


                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

