import { Component, OnInit, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";

/* Material */
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { CommonService } from '../../../../_service/common.service';

/* Service */
import { UserDataService } from '../../../../_serviceAPI/user.data.service';
import { UserApiService } from '../../../../_serviceAPI/user.api.service';
import { BudgetDataService } from '../../../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../../../_serviceAPI/budget.api.service';

/* Model */
import { Dictionary } from '../../../../_model/dictionary';
import { PaymentSourceOptions } from '../../../../_model/getPaymentSourceOptions';
import { ProjectedBudget } from '../../../../_model/getProjectedBudget';
import { SubmitPaymentItem } from '../../../../_model/submitPaymentItem';

/* Pipe */
import { DictionaryPipe } from '../../../../_filters/dictionary.pipe';

/* Operators */
import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';


@Component({
  selector: 'app-create-payment-item',
  templateUrl: './create-payment-item.component.html',
  styleUrls: ['./create-payment-item.component.css']
})
export class CreatePaymentItemComponent implements OnInit {

  title!:string;
  data!:any;

  dictionaryObj!: Dictionary[];
  paymentSrcObj!:PaymentSourceOptions[];
  projectedBudget!:ProjectedBudget[];
  submitPaymentItemObj:SubmitPaymentItem = new SubmitPaymentItem();

  cont: number = 0;

  f_amount:any = new FormControl('');
  f_currency:any = new FormControl('');
  f_source:any = new FormControl('');
  f_date:any = new FormControl('');
  
  /* errors */
  error_vendor_name:string = "";


  constructor(
    public bsModalRef: BsModalRef,
    private budgetService:BudgetDataService,
    private userService: UserDataService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {

    // simulate 3 requests with different delays (and catch error)
    this.spinner.show();

    this.cont = 0;

    forkJoin({
      requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++)),
      paymentSrc: this.budgetService.getPaymentSourceOptions().pipe(catchError(error => of(error)), tap(() => this.cont++))
    })
    .subscribe(({requestDic, paymentSrc}) => {
      this.dictionaryObj = requestDic['data'];
      this.paymentSrcObj = paymentSrc['data'];
      this.spinner.hide();
    })

    // Default Values
    this.f_amount.setValue('0.00');
    
    this.f_date.setValue('2024-04-22');
  }



  onSubmit() {

    this.submitPaymentItemObj.budget_product_account_guid = this.data.guid;
    this.submitPaymentItemObj.projected_income_guid = this.data.income_guid;

    this.submitPaymentItemObj.amount_paid = this.f_amount.value;
    this.submitPaymentItemObj.currency_type = this.f_currency.value;
    this.submitPaymentItemObj.payment_source_guid = this.f_source.value;
    this.submitPaymentItemObj.payment_date = this.f_date.value;


     this.budgetService.createPaymentItem(this.submitPaymentItemObj)
          .subscribe(
              (data:any) => {

                  if (data['status'][0]['msg_status'] != "success") {                    
                    
                    alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                  
                  } else {
                    
                    // alert('Payment Item Created');
                    
                    this.closeModal();
                    this.commonService.notifyOther({option:'refreshProjectedExpenses',value:true});

                    // this.commonService.notifyOther({option:'addInvoice',value:this.invoice});
                    // _this.contribution_in_progress = false;
                    // _this.contribution_submitted = true;
                    // _this.reset_input();                       
                  }
              },
              error => {
                    alert("ERROR - " + "a network error occurred");
              });

  }  // createPayment
    
  closeModal(): void {
    this.bsModalRef.hide();
    console.log('close modal issued');
    return;  
  };


}