import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import { TokenManager } from '../_serviceAPI/token-manager.service';
import { TokenManager } from '../_service/token-manager.service';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { ResourceModel } from '../_model/resource';

const API_URL = environment.apiUrl;

@Injectable()
export class ResourceApiService {

  deviceInfo = null;

  headersVendor = this.tokenManager.getTokenVendorString()

  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) {}


  public getAllResources() {
    {
        return this.http
        .post(API_URL + '/v01/GET/resource', {"filter_type":1, "filter_value":"", "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public getResource(n_resource_guid:string) {
    {
        return this.http
        .post(API_URL + '/v01/GET/client_resource', {"filter_type":2, "filter_value":n_resource_guid, "appsource":"web"} , { headers: this.tokenManager.getTokenVendorWithUser()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public deleteResource(n_resource_guid:string, n_delete_status:number) {
    {
        return this.http
        .post(API_URL + '/v01/DELETE/resource', {"resource_guid":n_resource_guid, "delete_status":n_delete_status, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public createResource(re:ResourceModel): Observable<ResourceModel> {
    {
        return this.http
        .post(API_URL + '/v01/CREATE/resource', {"first_name":re.first_name, "last_name":re.last_name, "display_name":re.display_name, 
          "organization_name":re.organization_name, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public updateResource(n_resource_guid:string, n_attr_name:string, n_attr_value:string) {
    {
        return this.http
        .post(API_URL + '/v01/UPDATE/resource', {"resource_guid":n_resource_guid, "attribute_name":n_attr_name, "attribute_value":n_attr_value, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}