import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { InvoiceDataService } from '../../_serviceAPI/invoice.data.service';
import { InvoiceApiService } from '../../_serviceAPI/invoice.api.service';

import { Invoices } from '../../_model/getInvoices';

/* Modal */
import { CreateInvComponent } from '../../main-modules/mod-invoice/modal/create-inv/create-inv.component';




// Modal - 7.1.0  Angular 11XX-12XX  Bootstrap 4xx or 5xx
// https://valor-software.com/ngx-bootstrap/#/documentation


@Component({
  selector: 'app-mod-invoice',
  templateUrl: './mod-invoice.component.html',
  styleUrls: ['./mod-invoice.component.css']
})
export class ModInvoiceComponent implements OnInit {

  is_api_active = false;

  invoiceModel!:Invoices[];

  modalRef?: BsModalRef; 
  
  displayedColumns: string[] = ['company_name', 'invoice_date', 'invoice_number', 'bill_to_name', 'description', 'invoice_amount', 'sales_tax_subtotal', 'total_payable', 'lastupdate', 'editorial'];

  dataSource!: MatTableDataSource<any>;
  
  constructor(
    private modalService: BsModalService,
    private _liveAnnouncer: LiveAnnouncer,
    private invoiceService:InvoiceDataService
  ) { }

  @ViewChild(MatSort) sort = new MatSort();


  ngAfterViewInit() {
    this.loadInvoices();
  }

  ngOnInit(): void {
  }

  /** Announce the change in sort state for assistive technology. */  
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  openCreateInvoiceModal() {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Create Modal'
      },
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
      keyboard: true
    };
    this.modalRef = this.modalService.show(CreateInvComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
  }

  

  loadInvoices() {

      this.is_api_active = true;

      this.invoiceService.getInvoices()
              .subscribe(
                  (data:any) => {


                      if (data['status'][0]['msg_status'] != "success") {

                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                      } else {                    

                        // Record Data
                        this.invoiceModel = data['data']
                        this.dataSource = new MatTableDataSource(this.invoiceModel);
                        this.dataSource.sort = this.sort;                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });
  }

}
