<div class="modal-header modal-custom-hdr">
    <div class="modal-title pull-left">Create Invoice</div>    
  </div>
  <div class="modal-body">    


    <!-- Material Form -->
    <div class="row">      
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Company Name</div>
      <div class="col-6" style="padding-top:25px;font-size:14px;">Life-Science.ai</div>
      <div class="col-3" style="padding-top:25px;font-size:10px;">Currency:&nbsp;CAD</div>
    </div>

    <div class="row">      
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Billable To:</div>
      <div class="col-6" style="padding-top:25px;font-size:14px;">Emerald Consulting</div>
      <div class="col-3" style="padding-top:25px;font-size:10px;">&nbsp;</div>
    </div>

    <div class="row" style="padding-top:25px;">
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Inv. #</div>
      <div class="col-2">
        <mat-form-field appearance="fill" [style.width.px]=55>
          <input matInput placeholder="" [formControl]="f_inv_number" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_inv_number.invalid"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="padding-top:5px;">
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Date</div>
      <div class="col-2">
        <mat-form-field appearance="fill" [style.width.px]=105>
          <input matInput placeholder="" [formControl]="f_inv_date" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_inv_date.invalid"></mat-error>
        </mat-form-field>
      </div>
    </div>
 
    <div class="row">
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Description</div>
      <div class="col-9">
        <mat-form-field appearance="fill" [style.width.px]=350>
          <textarea matInput placeholder="" [formControl]="f_description"></textarea>
          <mat-error *ngIf="f_description.invalid"></mat-error>
        </mat-form-field>
      </div>
    </div>



    <div class="row">
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Unit Price</div>
      <div class="col-1">
        <mat-form-field appearance="fill" [style.width.px]=65>
          <input matInput placeholder="" [formControl]="f_unit_price" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_unit_price.invalid"></mat-error>
        </mat-form-field>
      </div>


      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right">Hours</div>
      <div class="col-1">
        <mat-form-field appearance="fill" [style.width.px]=65>
          <input matInput placeholder="" [formControl]="f_inv_hours" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_inv_hours.invalid"></mat-error>
        </mat-form-field>
      </div>


      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right">Subtotal</div>
      <div class="col-1">
        <mat-form-field appearance="fill" [style.width.px]=90>
          <input matInput placeholder="" [formControl]="f_inv_subtotal" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_inv_subtotal.invalid"></mat-error>
        </mat-form-field>
      </div>

    </div>




    <div class="row">
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">HST</div>
      <div class="col-1">
        <mat-form-field appearance="fill" [style.width.px]=85>
          <input matInput placeholder="" [formControl]="f_can_hst" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_can_hst.invalid"></mat-error>
        </mat-form-field>
      </div>


      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right">GST</div>
      <div class="col-1">
        <mat-form-field appearance="fill" [style.width.px]=85>
          <input matInput placeholder="" [formControl]="f_can_gst" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_can_gst.invalid"></mat-error>
        </mat-form-field>
      </div>


      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right">QST</div>
      <div class="col-1">
        <mat-form-field appearance="fill" [style.width.px]=85>
          <input matInput placeholder="" [formControl]="f_can_qst" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_can_qst.invalid"></mat-error>
        </mat-form-field>
      </div>

    </div>


    <div class="row">
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Tax SubTotal</div>
      <div class="col-2">
        <mat-form-field appearance="fill" [style.width.px]=100>
          <input matInput placeholder="" [formControl]="f_sales_tax_subtotal" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_sales_tax_subtotal.invalid"></mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-2" style="padding-top:25px;font-size:14px;text-align:right;">Total Payable</div>
      <div class="col-2">
        <mat-form-field appearance="fill" [style.width.px]=100>
          <input matInput placeholder="" [formControl]="f_total_payable" maxlength="20" placeholder=""required>
          <mat-error *ngIf="f_total_payable.invalid"></mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-12" style="padding-top:25px;font-size:20px;text-align:right;">
        <button type="button" class="btn btn-light" style="color:#909090;" (click)="bsModalRef.hide()">Cancel</button>  &nbsp; &nbsp;
        <button type="button" class="btn btn-info" (click)="createInvoice()">Create Invoice</button>
      </div>      
    </div>



  </div>
