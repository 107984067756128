import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { BudgetDataService } from '../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../_serviceAPI/budget.api.service';

import { BudgetProjected } from '../../_model/getBudgetProjected';

import { CreateBudgetItemComponent } from '../../main-modules/mod-budget-item/modal/create-budget-item/create-budget-item.component';

@Component({
  selector: 'app-mod-budget-projected',
  templateUrl: './mod-budget-projected.component.html',
  styleUrls: ['./mod-budget-projected.component.css']
})
export class ModBudgetProjectedComponent implements OnInit {

  is_api_active = false;

  budgetProjected!:BudgetProjected[];

  modalRef?: BsModalRef;
  
  displayedColumns: string[] = ['projected_budget_label', 'archive_status', 'editorial'];

  dataSource!: MatTableDataSource<any>;

  n_url:string = "";
  n_url_filter:string = "";
    
  constructor(
    private modalService: BsModalService,
    private _liveAnnouncer: LiveAnnouncer,
    private budgetService:BudgetDataService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @ViewChild(MatSort) sort = new MatSort();


  ngAfterViewInit() {
    this.loadBudgetItems();
  }

  ngOnInit(): void {

        this.route.params
          .subscribe((value) => {
            this.n_url = value['menu_option'];
            this.n_url_filter = value['menu_filter'];
          });
  }


  /** Announce the change in sort state for assistive technology. */  
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  openCreateBudgetItemModal() {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Create Modal'
      },
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
      keyboard: true
    };
    this.modalRef = this.modalService.show(CreateBudgetItemComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
  }

  

  loadBudgetItems() {
      
      this.is_api_active = true;

      this.budgetService.getProjectedBudgets()
              .subscribe(
                  (data:any) => {
                      if (data['status'][0]['msg_status'] != "success") {
                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                      } else {

                        // Record Data
                        // console.log(data['data'][0]['TotalAmt']);
                        this.budgetProjected = data['data']                        
                        this.dataSource = new MatTableDataSource(this.budgetProjected);
                        this.dataSource.sort = this.sort;                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });
  }




}