import {AfterViewInit, Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";

import { Observer, Subscription } from 'rxjs';

import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatIconModule} from '@angular/material/icon'
import {MatProgressBarModule} from '@angular/material/progress-bar';

// import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
// import {MatTableDataSource, MatTableModule} from '@angular/material/table';
// import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { BudgetDataService } from '../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../_serviceAPI/budget.api.service';
import { CommonService } from '../../_service/common.service';


import { ProjectedBudget } from '../../_model/getProjectedBudget';

import { CreatePaymentItemComponent } from '../../main-modules/mod-projected-budget/modal/create-payment-item/create-payment-item.component';




@Component({
  selector: 'app-mod-projected-budget',
  templateUrl: './mod-projected-budget.component.html',
  styleUrls: ['./mod-projected-budget.component.css']
})
export class ModProjectedBudgetComponent implements OnInit, OnDestroy {

  private subscription!:Subscription;
  

  is_api_active = false;

  n_show_only_inscope = 1;

  projectedBudget!:ProjectedBudget[];

  modalRef?: BsModalRef;


  constructor(
    private modalService: BsModalService,
    private _liveAnnouncer: LiveAnnouncer,
    private budgetService:BudgetDataService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router
  ) { }



  ngOnInit(): void {

      this.subscription = this.commonService.notifyObservable$.subscribe((res) => {

          if (res.hasOwnProperty('option') && res.option === 'refreshProjectedExpenses') {
            if (res.value == true) {
              this.loadBudgetItems();
            }            
          };

      });

  }


  ngAfterViewInit() {
    this.loadBudgetItems();

  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }


  loadBudgetItems() {
      this.is_api_active = true;

      this.budgetService.getProjectedBudget()
              .subscribe(
                  (data:any) => {
                      if (data['status'][0]['msg_status'] != "success") {
                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                      } else {
                        // Record Data
                        this.projectedBudget = data['data']
                        // this.dataSource = new MatTableDataSource(this.budgetItemsModel);
                        // this.dataSource.sort = this.sort;                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });
  }





  addPayment(n_guid:string, n_income_guid:string, x:any) {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Create Payment',
        data: x
      },
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
      keyboard: true
    };
    this.modalRef = this.modalService.show(CreatePaymentItemComponent, initialState);

    this.modalRef.content.closeBtnName = 'Close';
    // this.loadBudgetItems();
  }




}
