
<!--
displayedColumns: string[] = ['institution_organization', 'internal_user_label', 'account_id', 'account_number', 'account_status_description', 'lastupdate', 'creationdate', 'editorial'];
-->

<p class="crud_title">Banks</p>
<br/>
<p class="crud_sub_title">The Bank Accounts which you have</p>

<br/>

<button type="button" class="btn btn-info btn-sm" (click)="openCreateBudgetItemModal()">Add Account</button>

<hr/>

<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
</mat-form-field>


<!-- Data Table -->
		<div class="col-sm-12 col-md-12 col-lg-12" class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)" class="">
			  <!--- Note that these columns can be defined in any order.
			        The actual rendered columns are set as a property on the row definition" -->




			  <!-- User Label -->
			  <ng-container matColumnDef="internal_user_label">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description"> User Label </th>
			    <td mat-cell *matCellDef="let element"> {{element.internal_user_label}} </td>
			  </ng-container>


			  <!-- Bank Name -->
			  <ng-container matColumnDef="bank_name">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description"> Bank </th>
			    <td mat-cell *matCellDef="let element"> {{element.bank_name}} </td>
			  </ng-container>


			  <!-- Country -->
			  <ng-container matColumnDef="bank_country_code">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description"> Country </th>
			    <td mat-cell *matCellDef="let element"> {{element.bank_country_code}} </td>
			  </ng-container>


			  <!-- Currency Code -->
			  <ng-container matColumnDef="account_currency">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description"> Currency </th>
			    <td mat-cell *matCellDef="let element"> {{element.account_currency}} </td>
			  </ng-container>


			  <!-- Account ID -->
			  <ng-container matColumnDef="account_id">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Acc. id"> Account ID </th>
			    <td mat-cell *matCellDef="let element"> {{element.account_id}} </td>
			  </ng-container>


			  <!-- Account Number -->
			  <ng-container matColumnDef="account_number">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Status Description"> Account Number </th>
			    <td mat-cell *matCellDef="let element"> {{element.account_number}} </td>
			  </ng-container>


			  <!-- Status -->
			  <ng-container matColumnDef="account_type_label">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Type"> Type </th>
			    <td mat-cell *matCellDef="let element"> {{element.account_type_label}} </td>
			  </ng-container>



			  <!-- Edit Column -->
			  <ng-container matColumnDef="editorial">
			    <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
			    <td mat-cell *matCellDef="let element">
			     <a href="/main/account-details/{{element.guid}}"> <span  style="color:#5F9EA0;">view details</span></a>
			 	</td>

			  </ng-container>


			  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			  <!-- Empty State -->
			  <!-- Row shown when there is no data. -->
		      <tr class="mat-row" *matNoDataRow>
		          <td *ngIf="is_api_active==true" class="mat-cell" [attr.colspan]="4">
		          	<span>Loading...</span>
		          	<mat-progress-bar mode="indeterminate" class="table-progressbar"></mat-progress-bar>
		          </td>
		          <td *ngIf="is_api_active==false" class="mat-cell" [attr.colspan]="4">
		          	<div style="padding-top:40px">** No data found</div>
		      	  </td>
		      </tr>

			</table>

			<mat-paginator [length]="100"
              [pageSize]="25"
              showFirstLastButtons
              [pageSizeOptions]="[25, 50, 100]">
</mat-paginator>

		</div>