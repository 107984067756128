<p class="crud_title"><span class="lead_title">Detailed</span> Budget Items</p>
<br/>
<p class="crud_sub_title">Detailed Budget Items for <b>{{budgetItemsModel.vendor_name}}</b>: {{budgetItemsModel.guid}}<br/>
</p>

<br/>

<!--
<button type="button" class="btn btn-info btn-sm" (click)="openCreateBudgetItemModal()">Create Item</button>
-->

<hr/>



	<div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field appearance="fill" [style.width.px]=380>
    		  <mat-label>Vendor Name</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.vendor_name}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>
    </div>


    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Amount Due</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.amount_due}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>

    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=80>
    		  <mat-label>Currency</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.vendor_currency_description}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>
    </div>




    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Category</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.product_category_type_description}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>

    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=280>
    		  <mat-label>Expense Type</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.expense_type_description}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>
    </div>



    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Frequency</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.frequency_type_description}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>

    	<div class="col-2">
    		<mat-form-field appearance="fill" [style.width.px]=130>
    		  <mat-label>Next Payment Date</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.next_payment_date}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>

    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=130>
    		  <mat-label>Days Until</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.days_until_payment}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>
    </div>


    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=280>
    		  <mat-label>Default Payment Src</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.default_payment_src}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>

    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=280>
    		  <mat-label>Account Info</mat-label>
	          <input matInput placeholder="" maxlength="30" placeholder="1.00" value="{{budgetItemsModel.account_info}}" readonly>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>
    </div>



    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field appearance="fill" [style.width.px]=670>
    		  <mat-label>Account Notes</mat-label>
    		  <textarea matInput placeholder="Ex. It makes me feel..." value="{{budgetItemsModel.account_notes}}" ></textarea>
	          <mat-error></mat-error>
	        </mat-form-field>
    	</div>
    </div>


    <br/>
    <br/>
    <br/>


    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<div>Outstanding Items: &nbsp; &nbsp;Total <span class="outTotalAmt">${{budgetItemsModel.outstanding_balance}}</span> &nbsp; &nbsp; &nbsp;
			<button type="button" class="btn btn-info btn-sm" (click)="openCreateItemModal()">+</button>
    		</div>  		
    		<hr/>
    	</div>
    </div>


    <div class="row" style="margin-top:20px;">
    	<div class="col-2">
    		<div class="outTitle">Inv. #</div>
    		<hr/>
    	</div>
    	<div class="col-1">
    		<div class="outTitle">Inv. Dte</div>
    		<hr/>
    	</div>
    	<div class="col-1">
    		<div class="outTitle">Inv. Amt</div>
    		<hr/>
    	</div>
    	<div class="col-1">
    		<div class="outTitle">Base Curr.</div>
    		<hr/>
    	</div>
    	<div class="col-2">
    		<div class="outTitle">Payment Sts</div>
    		<hr/>
    	</div>
    	<div class="col-1">
    		<div class="outTitle">Notes</div>
    		<hr/>
    	</div>
    	<div class="col-2">
    		<div>&nbsp;</div>
    	</div>
    </div>

    <div class="row" style="margin-top:20px;" *ngFor="let z of budgetItemsModel.outstanding_details">
    		<div class="col-2">
	    		<div class="outText">{{z.invoice_number}}</div>
	    		<hr/>
	    	</div>
	    	<div class="col-1">
	    		<div class="outText">{{z.invoice_date}}</div>
	    		<hr/>
	    	</div>
	    	
	    	<div class="col-1">
	    		<div class="outText">{{z.invoice_amount}}</div>
	    		<hr/>
	    	</div>

	    	<div class="col-1">
	    		<div class="outText base_currency">{{z.alt_currency_base}} </div>
	    		<hr/>
	    	</div>

	    	<div class="col-2">
	    		<div class="outText">{{z.payment_status_label}}</div>
	    		<hr/>
	    	</div>

	    	<div class="col-1">
	    		<div class="outText">{{z.invoice_notes}}</div>
	    		
	    	</div>

	    	<div class="col-2">
	    		<div>
	    			<span>[edit]</span> &nbsp;
	    			<span>[rmv]</span>
	    		</div>
	    		
	    	</div>		
    </div>







    







