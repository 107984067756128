<p class="crud_title">Tax Report 001</p>
<br/>
<p class="crud_sub_title">Show Tax Report 001 To Compare To Government Numbers</p>

<br/>

<hr/>


<div *ngFor="let ndata of taxAuditReport001" style="margin-top:15px;">

	<div *ngFor="let x of ndata.value_set"class="row">
		<div class="col-2">{{x.assigned_date}}</div>
		<div class="col-3">{{x.item_dictionary_name}} | {{x.item_type}}</div>
		<div class="col-2" style="text-align:right;">{{x.item_amount}}
			<span *ngIf="x.is_credit">CR</span>
			<span *ngIf="!x.is_credit">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
		</div>
		<div class="col-3" style="font-size:10px;color:#909090;">
		{{x.category_dictionary_name}} - {{x.category_name}}
		</div>
		<!--
			<div class="col-4">{{x.category_name}}</div>
		-->

	</div>
	
</div>