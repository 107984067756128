<!--
	'vendor_name', 'product_category_type_description', 'expense_type_description', 'account_info', 'account_notes', 'outstanding_balance'
-->

<p class="crud_title"><span class="lead_title">{{n_url_filter}}</span> Open Debt Items</p>
<br/>
<p class="crud_sub_title">This includes:<br/>
	All Items Deemed to be Open Debt
</p>

<br/>

<button type="button" class="btn btn-info btn-sm" (click)="openCreateBudgetItemModal()">Create Item</button>

<hr/>




<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
</mat-form-field>

<div style="float:right;">
	<span class="report-totals">Upcoming Amt Due: ${{ n_base_currency_amt_due }}</span>
	&nbsp; &nbsp;
	<span class="report-totals">Outstanding Balance: ${{ n_base_currency_outstanding_balance }}</span>
</div>


<!-- Data Table -->
		<div class="col-sm-12 col-md-12 col-lg-12" class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)" class="">
			  <!--- Note that these columns can be defined in any order.
			        The actual rendered columns are set as a property on the row definition" -->

			  <!-- User Label -->
			  <ng-container matColumnDef="vendor_name">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Vendor </th>
			    <td mat-cell *matCellDef="let element"> {{element.vendor_name}} </td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="product_category_type_description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Category </th>
			    <td mat-cell *matCellDef="let element"> {{element.product_category_type_description}} </td>
			  </ng-container>

			  <!-- User Label -->
			  <ng-container matColumnDef="expense_type_description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Expense Type</th>
			    <td mat-cell *matCellDef="let element"> {{element.expense_type_description}} </td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="amount_due">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Budget Amt</th>
			    <td mat-cell *matCellDef="let element"> ${{element.amount_due}} {{element.vendor_currency_type}}</td>
			  </ng-container>

			 <!-- User Label -->
			  <ng-container matColumnDef="alt_currency_base">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Base Currency</th>
			    <td mat-cell *matCellDef="let element"> <span class="base_currency">{{element.alt_currency_base}}</span>  </td>
			  </ng-container>

			  <!-- User Label -->
			  <ng-container matColumnDef="frequency_type_description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Frequency</th>
			    <td mat-cell *matCellDef="let element"> {{element.frequency_type_description}} </td>
			  </ng-container>

			  <!-- User Label -->
			  <ng-container matColumnDef="next_payment_date">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Due Date</th>
			    <td mat-cell *matCellDef="let element"> {{element.next_payment_date}} </td>
			  </ng-container>

			  <!-- User Label -->
			  <ng-container matColumnDef="days_until_payment">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description" style="text-align:center;">Days Until</th>
			    <td mat-cell *matCellDef="let element"> <div style="text-align:left;padding-left:20px"> {{element.days_until_payment}} </div></td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="outstanding_balance">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Outstanding </th>
			    <td mat-cell *matCellDef="let element"> 
			    	<span class="outstanding_amt">${{element.outstanding_balance}}</span>&nbsp; <span class="outstanding_rcds" *ngIf="element.outstanding_total_rcds > 0">({{element.outstanding_total_rcds}}) </span> </td>
			  </ng-container>


			  <ng-container matColumnDef="default_payment_src">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Payment Src (default) </th>
			    <td mat-cell *matCellDef="let element"> {{element.default_payment_src}} </td>
			  </ng-container>

			  <!-- Edit Column -->
			  <ng-container matColumnDef="editorial">
			    <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
			    <td mat-cell *matCellDef="let element">
			     <a href="/main/budget-item-details/{{element.guid}}"> <span  style="color:#5F9EA0;">view details</span></a>
			 	</td>
			  </ng-container>



			  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			  <!-- Empty State -->
			  <!-- Row shown when there is no data. -->
		      <tr class="mat-row" *matNoDataRow>
		          <td *ngIf="is_api_active==true" class="mat-cell" [attr.colspan]="4">
		          	<span>Loading...</span>
		          	<mat-progress-bar mode="indeterminate" class="table-progressbar"></mat-progress-bar>
		          </td>
		          <td *ngIf="is_api_active==false" class="mat-cell" [attr.colspan]="4">
		          	<div style="padding-top:40px">** No data found</div>
		      	  </td>
		      </tr>

			</table>

			<mat-paginator [length]="100"
              [pageSize]="25"
              showFirstLastButtons
              [pageSizeOptions]="[25, 50, 100]">
</mat-paginator>

		</div>


<div style="margin-top:50px;margin-bottom:10px;">
	<span class="budget_title">Open Debt Budget Notes</span>
</div>
<div>
	<ul>
		<li>Need to Call Rogers and Review Amount Owing</li>
		<li>Alectra - 166.89 CAD Owing / 89.89 CAD Owing</li>
		<li>Enbridge - 182.53 CAD /  37.41 CAD | 222.00</li>
	</ul>
</div>




