import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ActivatedRoute} from '@angular/router';

import {UserDataService} from '../../_serviceAPI/user.data.service';
import { TokenManager } from '../../_service/token-manager.service';

import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

/* Third Party */
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

/* rxjs */
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  showPassword = false;

  user_guid = new FormControl('');

  user_email_address = new FormControl('');
  user_password = new FormControl('');

  in_progress:boolean = false;

  pwrd_type: string = 'password';


  // Fancy Redirect
  subscription:Subscription = Subscription.EMPTY;

  observablex = new Observable(subscriber => {
    setTimeout(() => {
      subscriber.complete();
    }, 1000);
  });


  constructor(

    private route:ActivatedRoute,
    private tokenManager: TokenManager,
    private http: HttpClient, 
    private userService:UserDataService,
    public zone: NgZone,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
    // private toastr: ToastrService
    ) { }

  ngOnInit(): void {
  }

  // toggle password field

  toggleShow() {
    if (this.pwrd_type== "text" ) {
      this.pwrd_type = 'password'
    } else {
      this.pwrd_type = 'text'
    }
  }




  user_login(): void{ 

    const _this = this;

    this.spinner.show();

    // Make The Call To Service (To Create A User)
    this.userService.userLogin(this.user_email_address.value, this.user_password.value)
              .subscribe(
                  (data:any) => {

                      if (data['status'][0]['msg_status'] != "success") {

                        // Error
                        console.log(data['status']);

                        this.toastr.error('', "ERROR - " + data['status'][0]['msg_data']['msg_info']['description_text']);

                      } else {

                        // Show Success                        
                        let jwt_result = data['data'][0]['jwt_token'];

                        this.tokenManager.storeToken(jwt_result);

                        this.subscription  = this.observablex.subscribe({
                              error(err:any) { console.error('something wrong occurred: ' + err); },
                              complete() { 
                                  _this.zone.runOutsideAngular(() => {
                                    window.location.href = "/main";
                                  });                                
                              }
                            });
                      }

                      this.spinner.hide();
                  },
                  error => {
                      this.spinner.hide();
                      this.toastr.error('', "ERROR - A Network Error Occured");
                  });


  }

}
