<ngx-spinner bdOpacity = 0.9 bdColor = "#fff" size = "medium" color = "#6495ED" type = "ball-spin-clockwise" [fullScreen] = "false"><p style="color:#6495ED;padding-top:120px;" > Loading... </p></ngx-spinner>


<div class="modal-header modal-custom-hdr">
    <div class="modal-title pull-left">Create Payment Source</div>    
</div>
<div class="modal-body">




	<!-- PAYMENT TYPE -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field>
			  <mat-label>Payment Type</mat-label>
  			  <select matNativeControl required [formControl]="f_paymentType">
  			    <option label="-- Select Type --"></option>
  			    <option *ngFor="let z of dictionaryObj | dictionaryfilter:600; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
  			  </select>
		    </mat-form-field>
    	</div>
    </div>


    <!-- User Label -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-8">
    		<mat-form-field appearance="fill" [style.width.px]=350>
    		  <mat-label>User Label</mat-label>
	          <input matInput placeholder="" [formControl]="f_paymentSourceName" maxlength="100" placeholder="" required>
	          <mat-error *ngIf="f_paymentSourceName.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    </div>


    <!-- RELATED ACCOUNT SOURCE TYPE -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-6">
    		<mat-form-field [style.width.px]=350>
			  <mat-label>Acount Source</mat-label>
  			  <select matNativeControl required [formControl]="f_accountGuid">
  			    <option label="-- Account Source --"></option>
  			    <option *ngFor="let z of accountObj" value="{{z.guid}}" >{{z.internal_user_label}}</option>
  			  </select>
		    </mat-form-field>
    	</div>
    </div>



	<div class="row">
      <div class="col-12" style="padding-top:25px;font-size:20px;text-align:right;">
        <button type="button" class="btn btn-light" style="color:#909090;" (click)="bsModalRef.hide()">Cancel</button>  &nbsp; &nbsp;
        <button  type="button" class="btn btn-info" (click)="onSubmit()">Submit</button>
        <!--
        	<button type="button" class="btn btn-info" (click)="createInvoice()">Next</button>
    	-->
      </div>      
    </div>  	
</div>



