import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mod-bank-transactions',
  templateUrl: './mod-bank-transactions.component.html',
  styleUrls: ['./mod-bank-transactions.component.css']
})
export class ModBankTransactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
