import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { BudgetDataService } from '../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../_serviceAPI/budget.api.service';

import { BudgetItems } from '../../_model/getBudgetItems';

import { CreateBudgetItemComponent } from '../../main-modules/mod-budget-item/modal/create-budget-item/create-budget-item.component';

@Component({
  selector: 'app-mod-debt-management',
  templateUrl: './mod-debt-management.component.html',
  styleUrls: ['./mod-debt-management.component.css']
})
export class ModDebtManagementComponent implements OnInit {

      is_api_active = false;

      n_report_description!:string;

      budgetItemsModel!:BudgetItems[];

      modalRef?: BsModalRef;
      
      displayedColumns: string[] = ['vendor_name', 'product_category_type_description', 'expense_type_description', 'amount_due', 'alt_currency_base', 'frequency_type_description', 'next_payment_date', 'days_until_payment', 'outstanding_balance', 'default_payment_src', 'editorial'];

      dataSource!: MatTableDataSource<any>;

      n_url:string = "";
      n_url_filter:string = "";
      n_report_type:number = 3;


      n_base_currency_amt_due = "";
      n_base_currency_outstanding_balance = "";

  
      constructor(
        private modalService: BsModalService,
        private _liveAnnouncer: LiveAnnouncer,
        private budgetService:BudgetDataService,
        private route: ActivatedRoute,
        private router: Router
      ) { }

      @ViewChild(MatSort) sort = new MatSort();


      ngAfterViewInit() {
        this.loadBudgetItems();
      }

      ngOnInit(): void {

            this.route.params
              .subscribe((value) => {
                this.n_url = value['menu_option'];
                this.n_url_filter = value['menu_filter'];
                

                if (this.n_url_filter == 'debt-management') {
                  this.n_report_description = 'esitmated results for monthly, bi-weekly (x2) and weekly (x4) items';
                  this.n_report_type = 3;

                } else {
                  this.n_report_description = 'esitmated results for annual and quarterly (x4) items';
                  this.n_report_type = 3;
                }

              });



      }

      /** Announce the change in sort state for assistive technology. */  
      announceSortChange(sortState: Sort) {
        if (sortState.direction) {
          this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
          this._liveAnnouncer.announce('Sorting cleared');
        }
      }

      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }



      openCreateBudgetItemModal() {
        const initialState: ModalOptions = {
          initialState: {
            title: 'Create Modal'
          },
          class: 'modal-dialog-centered modal-lg',
          backdrop: 'static',
          keyboard: true
        };
        this.modalRef = this.modalService.show(CreateBudgetItemComponent, initialState);

        this.modalRef.content.closeBtnName = 'Close';
      }

      

      loadBudgetItems() {
          this.is_api_active = true;

          this.budgetService.getBudgetItems(this.n_report_type)
                  .subscribe(
                      (data:any) => {
                          if (data['status'][0]['msg_status'] != "success") {
                            alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                          } else {

                            // Record Data
                            // console.log(data['data'][0]['TotalAmt']);
                            this.n_base_currency_amt_due = data['data'][0]['TotalAmt'][0]['base_currency_amt_due'];
                            this.n_base_currency_outstanding_balance = data['data'][0]['TotalAmt'][0]['base_currency_outstanding_balance'];

                            this.budgetItemsModel = data['data'][0]['DataDetail']
                            this.dataSource = new MatTableDataSource(this.budgetItemsModel);
                            this.dataSource.sort = this.sort;                        
                          }

                          this.is_api_active = false;
                      },
                      error => {
                          alert("ERROR - " + "a network error occurred");
                      });
      }

      calculation() {
        let total_balance: number = 0;
        let total_amt_due: number = 0;

        if (this.dataSource)
          for (let row of this.dataSource.data) {
            if (row.id != 0) total_balance += +row.outstanding_balance;
            if (row.id != 0) total_amt_due += +row.amount_due;
          }
        return [total_balance, total_amt_due];
      }

}

