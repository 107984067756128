<ngx-spinner bdOpacity = 0.9 bdColor = "#fff" size = "medium" color = "#6495ED" type = "ball-spin-clockwise" [fullScreen] = "false"><p style="color:#6495ED;padding-top:120px;" > Loading... </p></ngx-spinner>


<div class="modal-header modal-custom-hdr">
    <div class="modal-title pull-left">Budget Items</div>    
</div>
<div class="modal-body">


	<!-- VENDOR -->
    <div class="row">
      	<div class="col-4">
	        <mat-form-field>
				  <mat-label>Vendor Name</mat-label>
				  <select matNativeControl required [formControl]="f_VendorGuid" (change)="onBudgetVendorChange($event)">
				    <option *ngFor="let z of budgetVendorModel; let i = index" value="{{z.guid}}">{{z.vendor_name}}</option>
				    <option value="999-999-999" label="** Add New Vendor"></option>
				  </select>
			</mat-form-field>
       	</div>

       	<div *ngIf="show_new_vendor == true" class="col-4">
       		<mat-form-field appearance="fill" [style.width.px]=250>
       		  <mat-label>Vendor Name</mat-label>
	          <input matInput placeholder="" [formControl]="f_input_vendorName" maxlength="100" placeholder=""required>
	          <mat-error *ngIf="f_input_vendorName.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
       	</div>

       	<div *ngIf="show_new_vendor == true" class="col-3">
    		<mat-form-field>
			  <mat-label>Currency Type</mat-label>
  			  <select matNativeControl required [formControl]="f_input_currencyType">
  			    <option label="-- Currency --"></option>
  			    <option value="CAD" >CAD - Canadian</option>
  			    <option value="USD" >USD - United States</option>
  			    <option *ngFor="let z of currencyList" value="{{z.currency_code}}" >{{z.currency_code}} {{z.currency_name}}</option>
  			  </select>
		    </mat-form-field>
       	</div>
    </div>



    <!-- PRODUCT / ACCOUNT -->
    <div class="row" style="margin-top:20px;">
    	<div class="col-4">
    		<mat-form-field>
			  <mat-label>Expense Category</mat-label>
  			  <select matNativeControl required [formControl]="f_expenseCategoryType">
  			    <option label="-- Select Category --"></option>
  			    <option *ngFor="let z of dictionaryObj | dictionaryfilter:300; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
  			  </select>
		    </mat-form-field>
    	</div>
    	
    	<div class="col-4">
    		<mat-form-field>
			  <mat-label>Expense Type</mat-label>
  			  <select matNativeControl required [formControl]="f_expenseType">
  			    <option label="-- Select Expense --"></option>
  			    <option *ngFor="let z of dictionaryObj | dictionaryfilter:400; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
  			  </select>
		    </mat-form-field>
    	</div>
    </div>

    <div class="row" style="margin-top:20px;">
    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=250>
    		  <mat-label>Account Info</mat-label>
	          <input matInput placeholder="" [formControl]="f_input_accountInfo" maxlength="100" placeholder="a/c # - email@email.com" required>
	          <mat-error *ngIf="f_input_accountInfo.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=250>
    		  <mat-label>Account Notes</mat-label>
	          <input matInput placeholder="" [formControl]="f_input_accountNotes" maxlength="100" placeholder="" required>
	          <mat-error *ngIf="f_input_accountNotes.invalid">{{error_vendor_name}}</mat-error>
	          <!--
	          <mat-hint>50 Characters</mat-hint>
	          -->
	        </mat-form-field>
    	</div>
    </div>


    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Default Amount Due</mat-label>
	          <input matInput placeholder="" [formControl]="f_input_amtDueDefault" maxlength="30" placeholder="1.00" required>
	          <mat-error *ngIf="f_input_amtDueDefault.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Default Amount Due Conv.</mat-label>	
	          <input matInput placeholder="" [formControl]="f_input_amtDueConv" maxlength="30" placeholder="0.00" required>
	          <mat-error *ngIf="f_input_amtDueConv.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    </div>





    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Outstanding Balance</mat-label>
	          <input matInput placeholder="" [formControl]="f_input_outstandingBalance" maxlength="50" placeholder="0.00" required>
	          <mat-error *ngIf="f_input_outstandingBalance.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    </div>






    <div class="row" style="margin-top:20px;">
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Frequency Start</mat-label>
	          <input matInput placeholder="" [formControl]="f_input_frqStartDate" maxlength="30" placeholder="" required>
	          <mat-error *ngIf="f_input_frqStartDate.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    	<div class="col-3">
    		<mat-form-field appearance="fill" [style.width.px]=180>
    		  <mat-label>Frequency End</mat-label>	
	          <input matInput placeholder="" [formControl]="f_input_frqEndDate" maxlength="30" placeholder="" required>
	          <mat-error *ngIf="f_input_frqEndDate.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>
    </div>




    <div class="row" style="margin-top:10px;">
    	<div class="col-3">
    		<mat-form-field>
			  <mat-label>Frequency</mat-label>
  			  <select matNativeControl required [formControl]="f_frequencyType">
  			    <option label="-- Select Frequency --"></option>
  			    <option *ngFor="let z of dictionaryObj | dictionaryfilter:500; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
  			  </select>
		    </mat-form-field>
    	</div>

    	<div class="col-3">
    		<div *ngIf="f_frequencyType.value == 1">
	    		<mat-form-field appearance="fill" [style.width.px]=180>
	    		  <mat-label>Weekly Day Due</mat-label>
		          <input matInput placeholder="" [formControl]="f_input_weeklyDueDate" maxlength="30" placeholder=""required>
		          <mat-error *ngIf="f_input_weeklyDueDate.invalid">{{error_vendor_name}}</mat-error>
		        </mat-form-field>
	       	</div>

	       	<div *ngIf="f_frequencyType.value == 3">
		       	<mat-form-field appearance="fill" [style.width.px]=180>
		       	  <mat-label>Monthly Due Date</mat-label>
		          <input matInput placeholder="" [formControl]="f_input_mthDueDate" maxlength="30" placeholder=""required>
		          <mat-error *ngIf="f_input_mthDueDate.invalid">{{error_vendor_name}}</mat-error>
		        </mat-form-field>
	    	</div>

	    	<div *ngIf="f_frequencyType.value == 5">
	    		<mat-form-field appearance="fill" [style.width.px]=180>
	    			<mat-label>Annual Due Date</mat-label>
	          		<input matInput placeholder="" [formControl]="f_input_annualDueDate" maxlength="30" placeholder=""required>
	          		<mat-error *ngIf="f_input_annualDueDate.invalid">{{error_vendor_name}}</mat-error>
	        	</mat-form-field>
	       	</div>
    	</div>    	
    </div>



    <div class="row" style="margin-top:10px;">
    	<div class="col-8">
		    <mat-form-field [style.width.px]=480>
				  <mat-label>Select Payment Source</mat-label>
				  <select matNativeControl required [formControl]="f_inputPaymentSrc">
				    <option *ngFor="let z of paymentSrcObj; let i = index" value="{{z.guid}}">{{z.payment_type_description}}  |  {{z.payment_source_name}}</option>
				  </select>
			</mat-form-field>
		</div>
	</div>



	<div class="row">
      <div class="col-12" style="padding-top:25px;font-size:20px;text-align:right;">
        <button type="button" class="btn btn-light" style="color:#909090;" (click)="bsModalRef.hide()">Cancel</button>  &nbsp; &nbsp;
        <button  type="button" class="btn btn-info" (click)="onSubmit()">Next</button>
        <!--
        	<button type="button" class="btn btn-info" (click)="createInvoice()">Next</button>
    	-->
      </div>      
    </div>  	
</div>

