import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { BudgetDataService } from '../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../_serviceAPI/budget.api.service';

import { BudgetItems } from '../../_model/getBudgetItems';

import { CreateBudgetItemComponent } from '../../main-modules/mod-budget-item/modal/create-budget-item/create-budget-item.component';
import { CreateOutstandingItemComponent } from '../../main-modules/mod-budget-item-details/modal/create-outstanding-item/create-outstanding-item.component';

@Component({
  selector: 'app-mod-budget-item-details',
  templateUrl: './mod-budget-item-details.component.html',
  styleUrls: ['./mod-budget-item-details.component.css']
})
export class ModBudgetItemDetailsComponent implements AfterViewInit {

  is_api_active = false;

  // budgetItemsModel!:BudgetItems[];
  // accountObj: AccountSummary = new AccountSummary();
  budgetItemsModel:BudgetItems = new BudgetItems();
  budgetItemsM!:BudgetItems;


  n_url:string = "";
  n_url_filter:string = "";

  modalRef?: BsModalRef;


  n_report_type = 99;

  constructor(
    private modalService: BsModalService,
    private _liveAnnouncer: LiveAnnouncer,
    private budgetService:BudgetDataService,
    private route: ActivatedRoute,
    private router: Router
  ) { }



  ngAfterViewInit() {
    console.log("TEST MANNNNN");
    console.log('ngwork ngwork ngwork');
    // this.loadBudgetItems();  
  }



  ngOnInit(): void {

        this.route.params
          .subscribe((value) => {
            this.n_url = value['menu_option'];
            this.n_url_filter = value['menu_filter'];

            this.loadBudgetItems();

            /*
            if (this.n_url_filter == 'monthly') {
              this.n_report_description = 'esitmated results for monthly, bi-weekly (x2) and weekly (x4) items';
              this.n_report_type = 1;

            } else {
              this.n_report_description = 'esitmated results for annual and quarterly (x4) items';
              this.n_report_type = 2;
            }
            */

          });
  }



  openCreateItemModal() {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Create Modal',
        nData: this.budgetItemsModel,
        name_of_vendor: this.budgetItemsModel.vendor_name,
        parent_guid_budget_line: this.budgetItemsModel.guid
      },
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
      keyboard: true
    };
    this.modalRef = this.modalService.show(CreateOutstandingItemComponent, initialState);

    this.modalRef.content.closeBtnName = 'Close';
  }
  

  loadBudgetItems() {

      console.log('loaded loaded loaded');

      this.is_api_active = true;

      console.log("PARAM");
      console.log(this.n_url_filter);

      this.budgetService.getBudgetItemWithDetail(this.n_url_filter)
              .subscribe(
                  (data:any) => {
                      if (data['status'][0]['msg_status'] != "success") {
                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                      } else {
                        // Record Data
                        // this.budgetItemsModel = data['data'][0];
                        this.budgetItemsModel = data['data'][0]['DataDetail'][0];
                        console.log("1111111");
                        console.log(this.budgetItemsModel)                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });
  }



/*
  calculation() {
    let total_balance: number = 0;
    let total_amt_due: number = 0;

    if (this.dataSource)
      for (let row of this.dataSource.data) {
        if (row.id != 0) total_balance += +row.outstanding_balance;
        if (row.id != 0) total_amt_due += +row.amount_due;
      }
    return [total_balance, total_amt_due];
  }
*/


}
