import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mod-investments',
  templateUrl: './mod-investments.component.html',
  styleUrls: ['./mod-investments.component.css']
})
export class ModInvestmentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
