import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-investments',
  templateUrl: './create-investments.component.html',
  styleUrls: ['./create-investments.component.css']
})
export class CreateInvestmentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
