import { Component, OnInit, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";

/* Material */
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { DictionaryPipe } from '../../../../_filters/dictionary.pipe';

import { CommonService } from '../../../../_service/common.service';

import { UserDataService } from '../../../../_serviceAPI/user.data.service';
import { AccountDataService } from '../../../../_serviceAPI/account.data.service';

import { Dictionary } from '../../../../_model/dictionary';
import { BankList } from '../../../../_model/getBankList';

import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';

import { AccountSummary } from '../../../../_model/getAccountSummary';

import { submitBudgetItem } from '../../../../_model/submitBudgetItem';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {


  dictionaryObj!: Dictionary[];
  bankObj!: BankList[];

  accountObj: AccountSummary = new AccountSummary();

  // budgetVendorModel!:BudgetVendor[];
  // budgetVM!:BudgetVendor[];
  // paymentSrcObj!:PaymentSourceOptions[];
  // submitBudgetItmObj:submitBudgetItem = new submitBudgetItem();

  cont: number = 0;

  show_new_vendor:boolean = false;


  f_Institution:any = new FormControl('');
  
  f_Country:any = new FormControl('');
  f_BankGuid:any = new FormControl('');

  f_Currency:any = new FormControl('');
  f_AccountType:any = new FormControl('');

  f_UserLabel:any = new FormControl('');
  f_AccountId:any = new FormControl('');
  f_AccountNumber:any = new FormControl('');

  f_Branch:any = new FormControl('');
  f_Routing:any = new FormControl('');
  f_SwiftCode:any = new FormControl('');


  /* errors */
  error_vendor_name:string = "";


  constructor(public bsModalRef: BsModalRef,
    private accountService:AccountDataService,
    private userService: UserDataService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {

        // simulate 3 requests with different delays (and catch error)
        this.spinner.show();

        this.cont = 0;

        forkJoin({
          requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++)),
          bankList: this.userService.getBanks().pipe(catchError(error => of(error)), tap(() => this.cont++))
        })
        .subscribe(({requestDic, bankList}) => {
          this.dictionaryObj = requestDic['data'];
          this.bankObj = bankList['data'];
          this.spinner.hide();
        })

        // TEST VALUES
      }



      onSubmit() {

        this.accountObj.bank_guid = this.f_BankGuid.value;
        this.accountObj.currency_code = this.f_Currency.value;
        this.accountObj.account_type = this.f_AccountType.value;
        this.accountObj.internal_user_label = this.f_UserLabel.value;
        this.accountObj.account_id = this.f_AccountId.value;
        this.accountObj.account_number = this.f_AccountNumber.value;

        this.accountObj.branch_id = this.f_Branch.value;
        this.accountObj.routing_number = this.f_Routing.value;
        this.accountObj.swift_code = this.f_SwiftCode.value;

        this.accountService.createAccount(this.accountObj)
                  .subscribe(
                      (data:any) => {

                          // console.log('show data');
                          // console.log(data)

                          if (data['status'][0]['msg_status'] != "success") {
                            
                            alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                          } else {                    
                            
                            alert('Account Created');

                            // this.commonService.notifyOther({option:'addInvoice',value:this.invoice});

                            // _this.contribution_in_progress = false;
                            // _this.contribution_submitted = true;
                            // _this.reset_input();                       
                          }
                      },
                      error => {
                          alert("ERROR - " + "a network error occurred");
                      });

      }  // createAccount
        



      closeModal(): void {
        console.log('close modal issued');
        return;  
  }



}
