import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { BudgetDataService } from '../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../_serviceAPI/budget.api.service';

import { BudgetProjectedDetails } from '../../_model/getBudgetProjectedDetails';

import { BudgetProjectedUpdateComponent } from '../../main-modules/mod-budget-projected-details/modal/budget-projected-update/budget-projected-update.component';



@Component({
  selector: 'app-mod-budget-projected-details',
  templateUrl: './mod-budget-projected-details.component.html',
  styleUrls: ['./mod-budget-projected-details.component.css']
})
export class ModBudgetProjectedDetailsComponent implements OnInit {

      nTitle!:string;

      is_api_active = false;

      budgetProjectedDetails!:BudgetProjectedDetails[];

      modalRef?: BsModalRef;
            
      n_url:string = "";
      n_url_filter:string = "";
        
      constructor(
        private modalService: BsModalService,
        private budgetService:BudgetDataService,
        private route: ActivatedRoute,
        private router: Router
      ) { }
      

      ngAfterViewInit() {
        this.loadBudgetItems();
      }

      ngOnInit(): void {

            this.route.params
              .subscribe((value) => {
                this.n_url = value['menu_option'];
                this.n_url_filter = value['menu_filter'];
              });
      }

      openUpdateProjectedModal() {
        const initialState: ModalOptions = {
          initialState: {
            nLabelGuid: this.n_url_filter,
            nLabelTitle: this.nTitle,
            title: 'Create Modal'
          },
          class: 'modal-dialog-centered modal-lg',
          backdrop: 'static',
          keyboard: true
        };
        this.modalRef = this.modalService.show(BudgetProjectedUpdateComponent, initialState);
        this.modalRef.content.closeBtnName = 'Close';
      }

      

      loadBudgetItems() {
          
          this.is_api_active = true;

          this.budgetService.getProjectedBudgetDetails(this.n_url_filter)
                  .subscribe(
                      (data:any) => {
                          if (data['status'][0]['msg_status'] != "success") {
                            alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                          } else {

                            // Record Data
                            // console.log(data['data'][0]['TotalAmt']);
                            this.nTitle = data['data']['Totals']['label'];
                            this.budgetProjectedDetails = data['data']['RcdData'];

                            console.log(this.budgetProjectedDetails);

                            // this.dataSource = new MatTableDataSource(this.budgetProjected);
                            // this.dataSource.sort = this.sort;                        
                          }

                          this.is_api_active = false;
                      },
                      error => {
                          alert("ERROR - " + "a network error occurred");
                      });
      }

}