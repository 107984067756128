<!--
	'vendor_name', 'product_category_type_description', 'expense_type_description', 'account_info', 'account_notes', 'outstanding_balance'
-->

<p class="crud_title"><span class="lead_title">{{n_url_filter}}</span>Projected Budget</p>

<br/>
<p class="crud_sub_title">Projected Budget Reports:</p>

<br/>

<button type="button" class="btn btn-info btn-sm" (click)="openCreateBudgetItemModal()">Create Projected Budget</button>

<hr/>


<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
</mat-form-field>


<!-- Data Table -->
		<div class="col-sm-12 col-md-12 col-lg-12" class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)" class="">
			  <!--- Note that these columns can be defined in any order.
			        The actual rendered columns are set as a property on the row definition" -->

			  <!-- User Label -->
			  <ng-container matColumnDef="projected_budget_label">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Name </th>
			    <td mat-cell *matCellDef="let element"> {{element.projected_budget_label}} </td>
			  </ng-container>


			  <!-- User Label -->
			  <ng-container matColumnDef="archive_status">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Status </th>
			    <td mat-cell *matCellDef="let element"> {{element.archive_status}} </td>
			  </ng-container>


			  <!-- Edit Column -->
			  <ng-container matColumnDef="editorial">
			    <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
			    <td mat-cell *matCellDef="let element">
			     <a href="/main/projected-budget-details/{{element.guid}}"> <span  style="color:#5F9EA0;">view details</span></a>
			 	</td>
			  </ng-container>



			  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			  <!-- Empty State -->
			  <!-- Row shown when there is no data. -->
		      <tr class="mat-row" *matNoDataRow>
		          <td *ngIf="is_api_active==true" class="mat-cell" [attr.colspan]="4">
		          	<span>Loading...</span>
		          	<mat-progress-bar mode="indeterminate" class="table-progressbar"></mat-progress-bar>
		          </td>
		          <td *ngIf="is_api_active==false" class="mat-cell" [attr.colspan]="4">
		          	<div style="padding-top:40px">** No data found</div>
		      	  </td>
		      </tr>

			</table>

			<mat-paginator [length]="100"
              [pageSize]="25"
              showFirstLastButtons
              [pageSizeOptions]="[25, 50, 100]">
</mat-paginator>

		</div>




