import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenManager } from '../_service/token-manager.service';

import { Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { Invoices } from '../_model/getInvoices';


const API_URL = environment.apiUrl;


@Injectable()
export class InvoiceApiService {

  deviceInfo:string = "";

  headersVendor = this.tokenManager.getTokenVendorString()

  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) {
      // this.epicFunction()
    }


  /* Pre-Registration */

  public getInvoices() {
    {
        return this.http
        .post(API_URL + '/v01/GET/invoice', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public getIncomeReport(date_from:string, date_to:string) {
    {
        return this.http
        .post(API_URL + '/v01/GET/income_report', {"date_from":date_from, "date_to":date_to, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }



  public createInvoice(invoice:Invoices): Observable<Invoices> {
    {
        return this.http
        .post(API_URL + '/v01/CREATE/invoice', {"invoice_number":invoice.invoice_number,"invoice_date":invoice.invoice_date, "company_guid":invoice.company_guid,
        "bill_to_guid":invoice.bill_to_guid, "hours":invoice.hours, "description":invoice.description, "unit_price":invoice.unit_price, "invoice_amount":invoice.invoice_amount,
        "subtotal":invoice.subtotal, "can_hst":invoice.can_hst, "can_gst":invoice.can_gst, "can_qst":invoice.can_qst, "sales_tax_subtotal":invoice.sales_tax_subtotal,
        "total_payable":invoice.total_payable, "currency_type":invoice.currency_type,
          "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}