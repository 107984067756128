import { Component, ViewChild, OnInit, AfterViewInit, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";

import { NgxSpinnerService } from "ngx-spinner";


/* Material */
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { CommonService } from '../../../../_service/common.service';

import { BudgetDataService } from '../../../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../../../_serviceAPI/budget.api.service';

import { BudgetItems } from '../../../../_model/getBudgetItems';


@Component({
  selector: 'app-budget-projected-update',
  templateUrl: './budget-projected-update.component.html',
  styleUrls: ['./budget-projected-update.component.css']
})
export class BudgetProjectedUpdateComponent implements AfterViewInit {

      nLabelGuid!:string;
      nLabelTitle!:string;

      is_api_active = false;

      budgetItemsModel!:BudgetItems[];

      n_url:string = "";
      n_url_filter:string = "";

      modalRef?: BsModalRef;

      n_report_type = 901;

      constructor(
        private modalService: BsModalService,
        private budgetService:BudgetDataService,
        private route: ActivatedRoute,
        private router: Router,
        private spinner: NgxSpinnerService,
        private commonService: CommonService
      ) { }



      ngAfterViewInit() {
        console.log("TEST MANNNNN");
        console.log('ngwork ngwork ngwork');
        // this.loadBudgetItems();  
      }



      ngOnInit(): void {

            this.route.params
              .subscribe((value) => {
                this.n_url = value['menu_option'];
                this.n_url_filter = value['menu_filter'];



                // this.loadBudgetItems();
                this.loadBudgetItems();  


              });
      }



      update_selection(completed:boolean, p_guid:string, a_guid:string) {
        
        this.is_api_active = true;

        this.budgetService.updateBudgetProjectedDetailsAddRmv(p_guid, a_guid)
                  .subscribe(
                      (data:any) => {
                          if (data['status'][0]['msg_status'] != "success") {
                            alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                          } else {
                            // Record Data
                            // this.budgetItemsModel = data['data'][0]['DataDetail'];
                            alert("Update has occured :::::" + p_guid + "|" + a_guid)                        
                          }

                          this.is_api_active = false;
                      },
                      error => {
                          alert("ERROR - " + "a network error occurred");
                      });
      }

      
      

      loadBudgetItems() {

          console.log('loaded loaded loaded');

          this.is_api_active = true;

          console.log("PARAM");
          console.log(this.n_url_filter);

          this.budgetService.getProjectedBudgetSelectable(this.nLabelGuid)
                  .subscribe(
                      (data:any) => {
                          if (data['status'][0]['msg_status'] != "success") {
                            alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                          } else {
                            // Record Data
                            // this.budgetItemsModel = data['data'][0];
                            this.budgetItemsModel = data['data'][0]['DataDetail'];
                            console.log("1111111");
                            console.log(this.budgetItemsModel)                        
                          }

                          this.is_api_active = false;
                      },
                      error => {
                          alert("ERROR - " + "a network error occurred");
                      });
      }



}