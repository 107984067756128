import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenManager } from '../_service/token-manager.service';

import { Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { TaxAuditCategories } from '../_model/getTaxAuditCategories';
import { TaxAuditDetails } from '../_model/getTaxAuditDetails';

const API_URL = environment.apiUrl;


@Injectable()
export class TaxAuditApiService {

  deviceInfo:string = "";

  headersVendor = this.tokenManager.getTokenVendorString()

  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) {
      // this.epicFunction()
    }



  public getTaxReport001() {
    {
        return this.http
        .post(API_URL + '/v01/GET/tax_audit_report_001', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }
  }


  public getTaxReport002() {
    {
        return this.http
        .post(API_URL + '/v01/GET/tax_audit_report_002', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }
  }


 /* Get Categories */

  public getTaxCategories() {
    {
        return this.http
        .post(API_URL + '/v01/GET/tax_audit_categories', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }



  public getTaxDetails() {
    {
        return this.http
        .post(API_URL + '/v01/GET/tax_audit_report', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public createTaxAuditCategory(n_type:number, n_name:string, n_date:string) {
    {
        return this.http
        .post(API_URL + '/v01/CREATE/tax_category', {"category_type":Number(n_type),"category_name":n_name, "assigned_date":n_date,
        "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public createTaxAuditItem(n_guid:string, n_itemType:number, n_amount:string, n_isCredit:number, n_notes:string) {
    {
        return this.http
        .post(API_URL + '/v01/CREATE/tax_item', {"category_guid":n_guid,"item_type":Number(n_itemType),"item_amount":n_amount,
          "is_credit":n_isCredit,"notes":n_notes,"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          return res;
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}