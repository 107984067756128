<div class="container-xl">

	<app-header></app-header>

	<div class="row">

		<div class="col-sm-12 col-md-12 col-xl-6">
			
			<div class="row rline1">
				<div class="col-12 txtline1">13.2% of People in North America Dont Trust Banks</div>
			</div>


			<div class="row rline2">
				<div class="col-12 txtline2">Manage Your Bank</div>
			</div>

			<div class="row rline3">
				<div class="col-12 txtline3">BankWise lets you control your bank so your bank does not control you</div>
			</div>


			<div class="row rline4">
				<div class="col-12 txtline4">
					<button class="btn btn-primary btn-sm me-1" (click)="openDialog()">Register for Closed Beta</button>
				</div>
			</div>
		
		</div>


		<div class="col-sm-12 col-md-12 col-xl-6 side_image text-center" >
			<img class="img-responsive"  src="/assets/images/bankwise_425.png">
		</div>
	

	</div>




	<app-footer></app-footer>	


</div>


