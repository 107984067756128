import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import { DictionaryPipe } from '../../_filters/dictionary.pipe';
import { Dictionary } from '../../_model/dictionary';

import { TaxAuditDataService } from '../../_serviceAPI/tax_audit.data.service';
import { TaxAuditApiService } from '../../_serviceAPI/tax_audit.api.service';

import { TaxAuditCategories } from '../../_model/getTaxAuditCategories';
// import { TaxAuditDetails } from '../../_model/getTaxAuditDetails';
import { TaxAuditReport001 } from '../../_model/getTaxAuditReport001';


import { UserDataService } from '../../_serviceAPI/user.data.service';
import { UserApiService } from '../../_serviceAPI/user.api.service';

import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';

@Component({
  selector: 'app-mod-tax-report',
  templateUrl: './mod-tax-report.component.html',
  styleUrls: ['./mod-tax-report.component.css']
})
export class ModTaxReportComponent implements OnInit {

  cont: number = 0;
  is_api_active = false;

  dictionaryObj!: Dictionary[];
  taxCategoriesModel!:TaxAuditCategories[];
  taxAuditReport001!:TaxAuditReport001[]; 
  
  constructor(
    private taxService:TaxAuditDataService,
    private userService: UserDataService
  ) { }

  // @ViewChild(MatSort) sort = new MatSort();

  ngAfterViewInit() {  
  }

  ngOnInit(): void {
    this.loadTaxDetails();
  }


  loadTaxDetails() {

      this.is_api_active = true;

      forkJoin({
        taxReport001: this.taxService.getTaxReport001().pipe(catchError(error => of(error)), tap(() => this.cont++) ),
        requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++))
      })
      .subscribe(({taxReport001, requestDic}) => {
        this.taxAuditReport001 = taxReport001['data']
        this.dictionaryObj = requestDic['data'];
        this.is_api_active = false;
        // this.spinner.hide();

        // this.taxCategoriesModel = this.taxCategoriesModel.sort(this.sortByDicName)
        this.dictionaryObj = this.dictionaryObj.sort(this.sortByName);
      })        
  }




  sortByName(a: any, b: any) {
      var nameA = a.code_value.toUpperCase(); // ignore upper and lowercase
      var nameB = b.code_value.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
  }


}
