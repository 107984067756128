import { Component, OnInit, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";

/* Material */
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { CommonService } from '../../../../_service/common.service';

import { UserDataService } from '../../../../_serviceAPI/user.data.service';

import { BudgetDataService } from '../../../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../../../_serviceAPI/budget.api.service';
import { BudgetItems } from '../../../../_model/getBudgetItems';
import { BudgetVendor } from '../../../../_model/getBudgetVendor';
import { PaymentSourceOptions } from '../../../../_model/getPaymentSourceOptions';


import { DictionaryPipe } from '../../../../_filters/dictionary.pipe';

import { Dictionary } from '../../../../_model/dictionary';
import { CurrencyList } from '../../../../_model/getCurrency';

import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';


import { submitBudgetItem } from '../../../../_model/submitBudgetItem';
import { OutstandingPayment } from '../../../../_model/submitOutstanding';



@Component({
  selector: 'app-create-outstanding-item',
  templateUrl: './create-outstanding-item.component.html',
  styleUrls: ['./create-outstanding-item.component.css']
})
export class CreateOutstandingItemComponent implements OnInit {

  name_of_vendor:string = "";
  parent_guid_budget_line:string = "";


  budgetVendorModel!:BudgetVendor[];
  budgetVM!:BudgetVendor[];
  dictionaryObj!: Dictionary[];
  paymentSrcObj!:PaymentSourceOptions[];
  currencyList!: CurrencyList[];

  submitBudgetItmObj:submitBudgetItem = new submitBudgetItem();
  outstandingPayment:OutstandingPayment = new OutstandingPayment();


  cont: number = 0;
  show_new_vendor:boolean = false;

  f_invNumber:any = new FormControl('');
  f_invDate:any = new FormControl('');
  f_invAmount:any = new FormControl('');
  f_invNotes:any = new FormControl('');
  f_paymentStatus:any = new FormControl('');

  error_vendor_name:string = "";


  constructor(public bsModalRef: BsModalRef,
    private budgetService:BudgetDataService,
    private userService: UserDataService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {

    this.spinner.show();

    this.cont = 0;

    forkJoin({
      requestVendor: this.budgetService.getBudgetVendor().pipe(catchError(error => of(error)), tap(() => this.cont++) ),
      requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++)),
      currStuff: this.userService.getCurrencies().pipe(catchError(error => of(error)), tap(() => this.cont++)),
      paymentSrc: this.budgetService.getPaymentSourceOptions().pipe(catchError(error => of(error)), tap(() => this.cont++))
    })
    .subscribe(({requestVendor, requestDic, currStuff, paymentSrc}) => {
      this.budgetVendorModel = requestVendor['data'];
      this.dictionaryObj = requestDic['data'];
      this.currencyList = currStuff['data'];
      this.paymentSrcObj = paymentSrc['data'];
      this.spinner.hide();
    })


    // TEST VALUES
    this.f_invDate.setValue("2024-10-31")
  }


  onSubmit() {

    this.outstandingPayment.parent_guid = this.parent_guid_budget_line;
    this.outstandingPayment.invoice_number = this.f_invNumber.value;
    this.outstandingPayment.invoice_date = this.f_invDate.value;
    this.outstandingPayment.invoice_amount = this.f_invAmount.value;
    this.outstandingPayment.invoice_notes = this.f_invNotes.value;
    this.outstandingPayment.payment_status = this.f_paymentStatus;


    console.log("parent_guid")
    console.log(this.parent_guid_budget_line)

    console.log("invoice_number")
    console.log(this.f_invNumber.value)

    console.log("invoice_date")
    console.log(this.f_invDate.value)

    console.log("invoice_amount")
    console.log(this.f_invAmount.value)

    console.log("invoice_notes")
    console.log(this.f_invNotes.value)

    console.log("payment_status")
    console.log(this.f_paymentStatus.value)


    this.budgetService.createBudgetOutstanding2(this.parent_guid_budget_line, this.f_invNumber.value, this.f_invDate.value, this.f_invAmount.value, this.f_invNotes.value, this.f_paymentStatus.value)
              .subscribe(
                  (data:any) => {

                      // console.log('show data');
                      // console.log(data)

                      if (data['status'][0]['msg_status'] != "success") {
                        
                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                      } else {                    
                        
                        alert('Budget Item Created');

                        // this.commonService.notifyOther({option:'addInvoice',value:this.invoice});

                        // _this.contribution_in_progress = false;
                        // _this.contribution_submitted = true;
                        // _this.reset_input();                       
                      }
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });

  }  // createBrand
    
  closeModal(): void {
    console.log('close modal issued');
    return;  
  };

}
