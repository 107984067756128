
<div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-12">
            <app-rpt-by-resource-assignee *ngIf="menu_option=='expenses-by-assignee'"></app-rpt-by-resource-assignee>
        </div>
    </div>
</div>


