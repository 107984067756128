import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {

  title = 'share';

  private sub: any;
  menu_option!: string;
  menu_filter!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
        
        this.sub = this.route.params.subscribe(params => {
           this.menu_option = params['menu_option']; // (+) converts string 'id' to a number
           
            // In a real app: dispatch action to load the details here.
            if (this.menu_option.toLowerCase() == 'entries') {
              this.menu_filter = params['menu_filter'];
            }
        });


        /*
          Domain                -   Module
          ------               -   ------
          expenses-by-assignee  -   rpt-by-resource-assignee

        */

        var complete_menu_list = ["expenses-by-assignee"];
      
        if (complete_menu_list.indexOf(this.menu_option.toLowerCase()) == -1) {
          this.router.navigate(['404']) 
        }

  } //ngOnInit


}
