import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenManager } from '../_service/token-manager.service';

import { Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { Invoices } from '../_model/getInvoices';
import { submitBudgetItem } from '../_model/submitBudgetItem';
import { SubmitPaymentItem } from '../_model/submitPaymentItem';

import { PaymentSourceOptions } from '../_model/getPaymentSourceOptions';

import { OutstandingPayment } from '../_model/submitOutstanding';



const API_URL = environment.apiUrl;


@Injectable()
export class BudgetApiService {

  deviceInfo:string = "";

  headersVendor = this.tokenManager.getTokenVendorString()

  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) {
      // this.epicFunction()
    }






    public updateBudgetProjectedDetailsAddRmv(nPguid:string, nAguid:string) {
      {
          return this.http
          .post(API_URL + '/v01/UPDATE/budget_projected_details_add_remove', {"projected_budget_header_guid":nPguid, "account_outstanding_guid":nAguid,
            "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )
      }
    }



    public getProjectedBudgets() {
    {
          return this.http
          .post(API_URL + '/v01/GET/projected_budgets', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }

    public getProjectedBudgetDetails(n_projected_budget_guid:string) {
    {
          return this.http
          .post(API_URL + '/v01/GET/projected_budget_details', {"projected_budget_guid":n_projected_budget_guid,"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }


    public getProjectedBudgetSelectable(n_budget_label_guid:string) {
    {
          return this.http
          .post(API_URL + '/v01/GET/budget_items', {"report_type":901, "budget_label_guid":n_budget_label_guid, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }




    public getProjectedBudget() {
    {
          return this.http
          .post(API_URL + '/v01/GET/projected_budget', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }




    public createBudgetItem(sbmBudgetItm:submitBudgetItem): Observable<submitBudgetItem> {
      {
          return this.http
          .post(API_URL + '/v01/CREATE/budget_item', {"vendor_guid":sbmBudgetItm.vendor_guid,"vendor_name":sbmBudgetItm.vendor_name, 
            "currency_code":sbmBudgetItm.currency_code, "expense_category_type":sbmBudgetItm.category_product_type, "expense_type":sbmBudgetItm.expense_type,
            "account_info":sbmBudgetItm.account_info, "account_notes":sbmBudgetItm.account_notes, "outstanding_balance":sbmBudgetItm.outstanding_balance,
            "amount_due":sbmBudgetItm.amount_due, "amount_due_conv":sbmBudgetItm.amount_due_conv, "frequency_type":sbmBudgetItm.frequency_type,
            "frequency_start_date":sbmBudgetItm.frequency_start_date, "frequency_end_date":sbmBudgetItm.frequency_end_date,
            "weekly_due_date":sbmBudgetItm.weekly_due_date, "monthly_due_date":sbmBudgetItm.month_due_date, "annual_due_date":sbmBudgetItm.annual_due_date,
            "annual_month_due":sbmBudgetItm.annual_month_due, "annual_day_due":sbmBudgetItm.annual_day_due,
            "payment_src":sbmBudgetItm.payment_src, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }




    public createPaymentItem(sbmPymtItm:SubmitPaymentItem): Observable<SubmitPaymentItem> {
      {
          return this.http
          .post(API_URL + '/v01/CREATE/payment', {"budget_product_account_guid":sbmPymtItm.budget_product_account_guid,
            "projected_income_guid":sbmPymtItm.projected_income_guid,
            "payment_source_guid":sbmPymtItm.payment_source_guid,"amount_paid":sbmPymtItm.amount_paid,
            "currency_type":Number(sbmPymtItm.currency_type),"payment_date":sbmPymtItm.payment_date,
            "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }




   /* Pre-Registration */

    public getPaymentSourceOptions() {
      {
          return this.http
          .post(API_URL + '/v01/GET/payment_source', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }

    public createPaymentSourceOption(pso:PaymentSourceOptions): Observable<PaymentSourceOptions> {
      {
          return this.http
          .post(API_URL + '/v01/CREATE/payment_source', {"payment_type":Number(pso.payment_type),
            "payment_source_name":pso.payment_source_name, "account_guid":pso.account_guid,
            "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }



    public createBudgetOutstanding(out:OutstandingPayment): Observable<OutstandingPayment> {
      {
          return this.http
          .post(API_URL + '/v01/CREATE/budget_outstanding', {"budget_line_parent_guid":out.parent_guid, "invoice_number":out.invoice_number,
            "invoice_date":out.invoice_date, "invoice_amount":out.invoice_amount, "invoice_notes":out.invoice_notes,
            "payment_status":out.payment_status,
            "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )
      }
    }




    public createBudgetOutstanding2(nParent:string, nNumber:string, nDate:string, nAmount:string, nNotes:string, nPayment:string) {
      {
          return this.http
          .post(API_URL + '/v01/CREATE/budget_outstanding', {"budget_line_parent_guid":nParent, "invoice_number":nNumber,
            "invoice_date":nDate, "invoice_amount":nAmount, "invoice_notes":nNotes,
            "payment_status":Number(nPayment),
            "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )
      }
    }




    public getBudgetVendor() {
      {
          return this.http
          .post(API_URL + '/v01/GET/budget_vendor', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }



    public getBudgetItems(n_report_type:number) {
      {
          return this.http
          .post(API_URL + '/v01/GET/budget_items', {"report_type":n_report_type, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )
      }
    }





    public getBudgetItemWithDetail(n_parent_line_guid:string) {
      {
          return this.http
          .post(API_URL + '/v01/GET/budget_items', {"report_type":99, "budget_line_parent_guid":n_parent_line_guid, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )
      }
    }



    public getBudgetItemsByAssignee(n_client_resource_guid:string) {
      {
          return this.http
          .post(API_URL + '/v01/GET/budget_items', {"report_type":701,"filter_value":n_client_resource_guid, "appsource":"web"} , { headers: this.tokenManager.getTokenVendorWithUser()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )
      }
    }



    public createInvoice(invoice:Invoices): Observable<Invoices> {
      {
          return this.http
          .post(API_URL + '/v01/CREATE/invoice', {"invoice_number":invoice.invoice_number,"invoice_date":invoice.invoice_date, "company_guid":invoice.company_guid,
          "bill_to_guid":invoice.bill_to_guid, "hours":invoice.hours, "description":invoice.description, "unit_price":invoice.unit_price, "invoice_amount":invoice.invoice_amount,
          "subtotal":invoice.subtotal, "can_hst":invoice.can_hst, "can_gst":invoice.can_gst, "can_qst":invoice.can_qst, "sales_tax_subtotal":invoice.sales_tax_subtotal,
          "total_payable":invoice.total_payable, "currency_type":invoice.currency_type,
            "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }


  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}