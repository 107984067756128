


<nav class="navbar navbar-expand-lg navbar-light" style="margin-top:25px;">
  <div class="container-fluid">


    <a class="navbar-brand" routerLink="/" style="margin-right:50px;">
        

        <span class="nav-link" class="hdr-mnu-logo std-black-logo">
            <span class="logo-icon">
            	<img class ="logo_display" src="../assets/images/logo/logo_32.png" />
            	<!--
                <img src="../assets/images/iconmonstr-book-19-24.png" />
            	-->
            </span>
            BankWise.app
        </span>
        <span style="font-size:11px;color:#FF0000;">(beta)</span>             
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">


        <!--  FEATURES
        <li class="nav-item">
          <a class="nav-link hdr-mnu-itm-additional std-grey-link" href="#">Features</a>
        </li>
        -->


        <li class="nav-item">
          <a class="nav-link hdr-mnu-itm-additional std-grey-link" routerLink="/faq">&nbsp;</a>
        </li>
      </ul>
      <form class="d-flex">
         <!--
         <a class="nav-link hdr-mnu-itm std-black" routerLink="/login">Log In</a>
         <button class="btn btn-outline-success me-1">Sign up</button>
         -->
         
         <!--
         <button class="btn btn-primary btn-sm me-1" routerLink="/register">Sign up</button>
     	 -->


	     <button class="btn btn-outline-primary btn-sm me-1" routerLink="/login">Login</button>

      </form>
    </div>
  </div>
</nav>

