import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BudgetApiService } from './budget.api.service';

import { Invoices } from '../_model/getInvoices';
import { Observable, observable } from 'rxjs';

import { submitBudgetItem } from '../_model/submitBudgetItem';
import { SubmitPaymentItem } from '../_model/submitPaymentItem';

import { PaymentSourceOptions } from '../_model/getPaymentSourceOptions';

import { OutstandingPayment } from '../_model/submitOutstanding';



@Injectable()
export class BudgetDataService {

  constructor(
    private http: HttpClient,
    private api: BudgetApiService    
  ) { }




  updateBudgetProjectedDetailsAddRmv(nPguid:string, nAguid:string) {
    return this.api.updateBudgetProjectedDetailsAddRmv(nPguid, nAguid)    
  }



  
  getProjectedBudgets() {
    return this.api.getProjectedBudgets()    
  }

  getProjectedBudgetDetails(n_projected_budget_guid:string) {
    return this.api.getProjectedBudgetDetails(n_projected_budget_guid)    
  }


  getProjectedBudget() {
    return this.api.getProjectedBudget()    
  }


  getPaymentSourceOptions() {
    return this.api.getPaymentSourceOptions()    
  }

  getBudgetVendor() {
    return this.api.getBudgetVendor()    
  }

  getBudgetItems(n_report_type:number) {
    return this.api.getBudgetItems(n_report_type)    
  }

  getBudgetItemWithDetail(n_parent_line_guid:string) {
    return this.api.getBudgetItemWithDetail(n_parent_line_guid)    
  }

  getBudgetItemsByAssignee(n_client_resource_guid:string) {
    return this.api.getBudgetItemsByAssignee(n_client_resource_guid)    
  }


  getProjectedBudgetSelectable(n_budget_label_guid:string) {
    
    console.log('strong man warrior');
    console.log(n_budget_label_guid);

    return this.api.getProjectedBudgetSelectable(n_budget_label_guid)    
  }



  createBudgetItem(sbmBudgetItm:submitBudgetItem): Observable<submitBudgetItem> {
    return this.api.createBudgetItem(sbmBudgetItm)
  }

  createPaymentItem(sbmPaymentItm:SubmitPaymentItem): Observable<SubmitPaymentItem> {
    return this.api.createPaymentItem(sbmPaymentItm)
  }


  createPaymentSourceOption(pso:PaymentSourceOptions): Observable<PaymentSourceOptions> {
    return this.api.createPaymentSourceOption(pso)
  }




  createBudgetOutstanding(tso:OutstandingPayment): Observable<OutstandingPayment> {
    return this.api.createBudgetOutstanding(tso)
  }

  createBudgetOutstanding2(nParent:string, nNumber:string, nDate:string, nAmount:string, nNotes:string, nPayment:string) {
    return this.api.createBudgetOutstanding2(nParent, nNumber, nDate, nAmount, nNotes, nPayment)
  }





  /*
  createBudgetAmountDue(n_budget_vendor_guid:string, n_amount_due:string, n_amount_converted:string) {
    return this.api.createBudgetAmountDue(n_budget_vendor_guid, n_amount_due, n_amount_converted)
  }

  createBudgetVendor(n_vendor_name:string, n_currency_type:number) {
    return this.api.createBudgetVendor(n_vendor_name, n_currency_type)
  }

  createBudgetProductAccount(n_budget_vendor_guid:string, n_product_category_type:number, n_account_info:string, n_expense_type:number,
   n_expense_tags:string, n_outstanding_balance:string, n_notes:string) {
    return this.api.createBudgetProductAccount(n_budget_vendor_guid, n_product_category_type, n_account_info, n_expense_type, n_expense_tags, n_outstanding_balance, n_notes)
  }

  createBudgetFrequency(n_budget_vendor_guid:string, n_frequency_type:number, n_start_date:string, n_end_date:string, n_monthly_due_date:number,
    n_weekly_day_of_week_due:number, n_annual_due_date:string) {
    return this.api.createBudgetFrequency(n_budget_vendor_guid, n_frequency_type, n_start_date, n_end_date, n_monthly_due_date,
    n_weekly_day_of_week_due, n_annual_due_date)
  }

  createBudgetPaymentSrcDefault(n_budget_vendor_guid:string, n_payment_option_guid:string) {
    return this.api.createBudgetPaymentSrcDefault(n_budget_vendor_guid, n_payment_option_guid)
  }
  */  

}