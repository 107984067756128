import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountApiService } from './account.api.service';

import { Observable, observable } from 'rxjs';

import { AccountSummary } from '../_model/getAccountSummary';




@Injectable()
export class AccountDataService {

  constructor(
    private http: HttpClient,
    private api: AccountApiService    
  ) { }


  getBankList() {
    return this.api.getBankList()    
  }

  



  getAccountSummary(n_type:number) {
    return this.api.getAccountSummary(n_type)    
  }

  getAccountDetails(n_account_reference_guid:string) {
    return this.api.getAccountDetails(n_account_reference_guid)    
  }


  createAccount(accountSummary:AccountSummary): Observable<AccountSummary> {
    return this.api.createAccount(accountSummary)
  }


}