export class SubmitPaymentItem {

  budget_product_account_guid!:string;
  projected_income_guid!:string;
  payment_source_guid!:string;

  amount_paid!:string;
  
  currency_type!:number;

  payment_date!:string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}