<section class="vh-100" style="background-color: #fff;">
  <div class="container py-5 h-100">
    
    <div class="row">
      <div class="col col-12 align-items-left">
      	<span class="date_range_title_txt">Date Range:&nbsp; &nbsp;</span>
      	<span class="date_range_dates_txt"> {{n_date_from}} to {{n_date_to}}</span>
      </div>
  	</div>

  	<div class="row" style="margin-top:25px;">
      <div class="col col-9 align-items-left">
      	<p class="grand_total_title_txt">Grand Total</p>
      </div>
      <div class="col col-3">
      	<p class="grand_total_amount_txt" style="float:left;">${{n_grand_total}}</p>
      </div>
  	</div>


  	<div *ngFor="let ndata of incomeReportModel" class="row" style="margin-top:15px;">
      
  		<!-- Year / Total Row -->
      	<div class="row" style="margin-top:45px;">
		      <div class="col col-9 align-items-left">
		      	<span class="date_range_title_txt" style="font-weight:bold;background-color:#ffff99;">Year:&nbsp;{{ndata.year}}</span>
		      </div>
		      <div class="col col-3 align-items-left">
		      	<span class="date_range_title_txt" style="font-weight:bold;background-color:#ffff99;">Total:&nbsp;${{ndata.total_payable}}</span>
		      </div>
  		</div>


  		<!-- Year / Details -->
      	<div class="row" style="margin-top:15px;">      	
		      	<!-- Half -->
		      	<div class="col col-2 align-items-left">
		      		<span class="standard_rpt_txt">&nbsp; &nbsp;SubTotal: ${{ndata.amount_subtotal}}</span>
		      	</div>

		      	<div class="col col-2 align-items-left">
		      		<span class="standard_rpt_txt">Hst: ${{ndata.can_hst}}</span>
		      	</div>

		      	<div class="col col-1 align-items-left">
		      		<span class="standard_rpt_txt">Gst: ${{ndata.can_gst}}</span>
		      	</div>

		      	<div class="col col-1 align-items-left">
		      		<span class="standard_rpt_txt">Qst: ${{ndata.can_qst}}</span>
		      	</div>		      	
		      	<!-- Half -->		      	
		      	
		      	<!-- End Half -->
		      	<div class="col col-3 align-items-left">
		      		<span class="standard_rpt_txt">TotalTax: ${{ndata.sales_tax_subtotal}}</span>
		      	</div>

		      	<div class="col col-3 align-items-left">
		      		<span class="standard_rpt_txt">Total:&nbsp;&nbsp; ${{ndata.total_payable}}</span>
		      	</div>
		      	<!-- End Half -->
      	</div>


      <div *ngFor="let zdata of ndata.monthly_summary" class="row" style="margin-top:25px;">
      	
      	<div class="col col-6 align-items-left">
      		<span class="mth_standard_rpt_txt">&nbsp; &nbsp; &nbsp; &nbsp;{{zdata.month_description}}, {{zdata.year}}</span>
      	</div>

      	<div class="col col-3 align-items-left">
      		<span class="mth_standard_rpt_txt">TotalTax:&nbsp;${{zdata.sales_tax_subtotal}}</span>
      	</div>

      	<!--
      	<div class="col col-2 align-items-left">
      		<span class="standard_rpt_txt">Sales Tax:&nbsp;${{zdata.amount_subtotal}}</span>
      	</div>
      	-->

      	<div class="col col-3 align-items-left">
      		<span class="mth_standard_rpt_txt">Total:&nbsp;${{zdata.total_payable}}</span>
      	</div>


      	<div class="row" style="margin-top:15px;">
      		
      		<div class="col col-1 align-items-left">
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">Inv #</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">Date</span>
      		</div>

      		<div class="col col-2 align-items-left">
      			<span class="mth_standard_rpt_txt">Company</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">Sub-total</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">Hst</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">Gst</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">Qst</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">TotalTax</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">Total</span>
      		</div>

      	</div>



      	<div *ngFor="let idata of zdata.invoice_details" class="row" style="margin-top:5px;">
      		
      		<div class="col col-1 align-items-left">
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">{{idata.invoice_number}}</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">{{idata.invoice_date}}</span>
      		</div>

      		<div class="col col-2 align-items-left">
      			<span class="mth_standard_rpt_txt">{{idata.company_name}}</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">${{idata.subtotal}}</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">${{idata.can_hst}}</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">${{idata.can_gst}}</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">${{idata.can_qst}}</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">${{idata.sales_tax_subtotal}}</span>
      		</div>

      		<div class="col col-1 align-items-left">
      			<span class="mth_standard_rpt_txt">${{idata.total_payable}}</span>
      		</div>

      	</div>

      </div>




  	</div>


  </div>
</section>





