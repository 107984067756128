import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { WaitComponent, NotifyDialog } from './landing/wait/wait.component';
import { HeaderComponent } from './landing/header/header.component';
import { FooterComponent } from './landing/footer/footer.component';
import { NotFoundComponent } from './landing/not-found/not-found.component';
import { LoginComponent } from './landing/login/login.component';


/* Material */
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input'; 
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule} from '@angular/material/select';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { MatSnackBarModule} from '@angular/material/snack-bar';


import { MatTableModule} from '@angular/material/table';
import { MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatSort, Sort, MatSortModule} from '@angular/material/sort';
import { MatDrawerMode, MatSidenavModule} from '@angular/material/sidenav';

import { MatRadioModule} from '@angular/material/radio';


/* Common Services */
import { AuthGuard } from './_service/auth.guard';
import { TokenManager } from './_service/token-manager.service';



/* API Services */
import { UserDataService } from './_serviceAPI/user.data.service';
import { UserApiService } from './_serviceAPI/user.api.service';

import { AccountDataService } from './_serviceAPI/account.data.service';
import { AccountApiService } from './_serviceAPI/account.api.service';

import { InvoiceDataService } from './_serviceAPI/invoice.data.service';
import { InvoiceApiService } from './_serviceAPI/invoice.api.service';

import { BudgetDataService } from './_serviceAPI/budget.data.service';
import { BudgetApiService } from './_serviceAPI/budget.api.service';

import { TaxAuditDataService } from './_serviceAPI/tax_audit.data.service';
import { TaxAuditApiService } from './_serviceAPI/tax_audit.api.service';

import { ResourceDataService } from './_serviceAPI/resource.data.service';
import { ResourceApiService } from './_serviceAPI/resource.api.service';


/* Third Party */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MainComponent } from './main/main.component';
import { ModSidemenuComponent } from './main-modules/mod-sidemenu/mod-sidemenu.component';
import { ModBankTransactionsComponent } from './main-modules/mod-bank-transactions/mod-bank-transactions.component';
import { ModDashboardComponent } from './main-modules/mod-dashboard/mod-dashboard.component';
import { ModAccountDetailsComponent } from './main-modules/mod-account-details/mod-account-details.component';
import { ModAccountComponent } from './main-modules/mod-account/mod-account.component';
import { ModInvoiceComponent } from './main-modules/mod-invoice/mod-invoice.component';
import { CreateInvComponent } from './main-modules/mod-invoice/modal/create-inv/create-inv.component';


// NGX-BOOTSTRAP
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonService } from './_service/common.service';
import { ModReportIncomeComponent } from './main-modules/mod-report-income/mod-report-income.component';
import { ModBudgetItemComponent } from './main-modules/mod-budget-item/mod-budget-item.component';

/* FILTER */
import { DictionaryPipe } from './_filters/dictionary.pipe';
import { CreateBudgetItemComponent } from './main-modules/mod-budget-item/modal/create-budget-item/create-budget-item.component';
import { ModDebtManagementComponent } from './main-modules/mod-debt-management/mod-debt-management.component';
import { CreateDebtItemComponent } from './main-modules/mod-debt-management/modal/create-debt-item/create-debt-item.component';
import { CreateDebtPaymentComponent } from './main-modules/mod-debt-management/modal/create-debt-payment/create-debt-payment.component';
import { ModReportBudgetComponent } from './main-modules/mod-report-budget/mod-report-budget.component';
import { ModTaxCategoriesComponent } from './main-modules/mod-tax-categories/mod-tax-categories.component';
import { ModTaxItemsComponent } from './main-modules/mod-tax-items/mod-tax-items.component';
import { ModProjectedBudgetComponent } from './main-modules/mod-projected-budget/mod-projected-budget.component';
import { CreatePaymentItemComponent } from './main-modules/mod-projected-budget/modal/create-payment-item/create-payment-item.component';
import { ModTaxReportComponent } from './main-modules/mod-tax-report/mod-tax-report.component';
import { ModTaxReport002Component } from './main-modules/mod-tax-report002/mod-tax-report002.component';

import { ModBanksComponent } from './main-modules/mod-account/mod-banks/mod-banks.component';
import { ModInvestmentsComponent } from './main-modules/mod-account/mod-investments/mod-investments.component';
import { ModAssetsComponent } from './main-modules/mod-account/mod-assets/mod-assets.component';
import { ModCreditComponent } from './main-modules/mod-account/mod-credit/mod-credit.component';


import { CreateAccountComponent } from './main-modules/mod-account/modal/create-account/create-account.component';

// import { CreateAccountComponent } from './main-modules/mod-account/modal/create-account/create-account.component';
// import { CreateBankComponent } from './main-modules/mod-account/modal/create-bank/create-bank.component';
// import { CreateCreditComponent } from './main-modules/mod-account/modal/create-credit/create-credit.component';
// import { CreateInvestmentComponent } from './main-modules/mod-account/modal/create-investment/create-investment.component';
// import { CreateAssetsComponent } from './main-modules/mod-account/modal/create-assets/create-assets.component';
// import { EditInvestmentComponent } from './main-modules/mod-account/mod-investment/modal/edit-investment/edit-investment.component';

// CreateBankComponent,
// CreateCreditComponent,
// CreateInvestmentComponent,
// CreateAssetsComponent,

import { CreateAssetComponent } from './main-modules/mod-account/mod-assets/modal/create-asset/create-asset.component';
import { EditAssetComponent } from './main-modules/mod-account/mod-assets/modal/edit-asset/edit-asset.component';


import { CreateBankComponent } from './main-modules/mod-account/mod-banks/modal/create-bank/create-bank.component';
import { EditBankComponent } from './main-modules/mod-account/mod-banks/modal/edit-bank/edit-bank.component';

import { EditCreditComponent } from './main-modules/mod-account/mod-credit/modal/edit-credit/edit-credit.component';

import { CreateInvestmentsComponent } from './main-modules/mod-account/mod-investments/modal/create-investments/create-investments.component';
import { EditInvestmentsComponent } from './main-modules/mod-account/mod-investments/modal/edit-investments/edit-investments.component';
import { ModPaymentSourceComponent } from './main-modules/mod-payment-source/mod-payment-source.component';
import { CreatePaymentSourceComponent } from './main-modules/mod-payment-source/modal/create-payment-source/create-payment-source.component';
import { ModBudgetItemDetailsComponent } from './main-modules/mod-budget-item-details/mod-budget-item-details.component';
import { CreateOutstandingItemComponent } from './main-modules/mod-budget-item-details/modal/create-outstanding-item/create-outstanding-item.component';
import { ModBudgetProjectedComponent } from './main-modules/mod-budget-projected/mod-budget-projected.component';
import { ModBudgetProjectedDetailsComponent } from './main-modules/mod-budget-projected-details/mod-budget-projected-details.component';
import { BudgetProjectedUpdateComponent } from './main-modules/mod-budget-projected-details/modal/budget-projected-update/budget-projected-update.component';
import { ShareComponent } from './share/share.component';

import { RptByResourceAssigneeComponent } from './share-modules/rpt-by-resource-assignee/rpt-by-resource-assignee.component';



@NgModule({
  declarations: [
    DictionaryPipe,
    AppComponent,
    WaitComponent,
    NotifyDialog,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    LoginComponent,
    MainComponent,
    ModSidemenuComponent,
    ModBankTransactionsComponent,
    ModDashboardComponent,
    ModAccountComponent,
    ModAccountDetailsComponent,
    ModInvoiceComponent,
    CreateInvComponent,
    ModReportIncomeComponent,
    ModBudgetItemComponent,
    CreateBudgetItemComponent,
    ModDebtManagementComponent,
    CreateDebtItemComponent,
    CreateDebtPaymentComponent,
    ModReportBudgetComponent,
    ModTaxCategoriesComponent,
    ModTaxItemsComponent,
    ModProjectedBudgetComponent,
    CreatePaymentItemComponent,
    ModTaxReportComponent,
    ModTaxReport002Component,
    CreateAccountComponent,
    ModBanksComponent,
    ModInvestmentsComponent,
    ModAssetsComponent,
    ModCreditComponent,
    CreateAssetComponent,
    EditAssetComponent,
    CreateBankComponent,
    EditBankComponent,
    EditCreditComponent,
    EditInvestmentsComponent,
    CreateInvestmentsComponent,
    ModPaymentSourceComponent,
    CreatePaymentSourceComponent,
    ModBudgetItemDetailsComponent,
    CreateOutstandingItemComponent,
    ModBudgetProjectedComponent,
    ModBudgetProjectedDetailsComponent,
    BudgetProjectedUpdateComponent,
    ShareComponent,
    RptByResourceAssigneeComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSliderModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatSidenavModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 10000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true
    }), // ToastrModule added
    ModalModule.forRoot()
  ],
  providers: [TokenManager, AuthGuard, UserApiService, UserDataService, AccountApiService, AccountDataService,
    InvoiceApiService, InvoiceDataService, CommonService, BudgetDataService, BudgetApiService, TaxAuditDataService, TaxAuditApiService,
    ResourceApiService, ResourceDataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
