import { Component, OnInit, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";

/* Material */
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { CommonService } from '../../../../_service/common.service';

import { UserDataService } from '../../../../_serviceAPI/user.data.service';

import { BudgetDataService } from '../../../../_serviceAPI/budget.data.service';
import { BudgetApiService } from '../../../../_serviceAPI/budget.api.service';
import { BudgetItems } from '../../../../_model/getBudgetItems';
import { BudgetVendor } from '../../../../_model/getBudgetVendor';
import { PaymentSourceOptions } from '../../../../_model/getPaymentSourceOptions';


import { DictionaryPipe } from '../../../../_filters/dictionary.pipe';

import { Dictionary } from '../../../../_model/dictionary';
import { CurrencyList } from '../../../../_model/getCurrency';

import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';

import { submitBudgetItem } from '../../../../_model/submitBudgetItem';

@Component({
  selector: 'app-create-budget-item',
  templateUrl: './create-budget-item.component.html',
  styleUrls: ['./create-budget-item.component.css']
})
export class CreateBudgetItemComponent implements OnInit {

  budgetVendorModel!:BudgetVendor[];
  budgetVM!:BudgetVendor[];
  dictionaryObj!: Dictionary[];
  paymentSrcObj!:PaymentSourceOptions[];
  currencyList!: CurrencyList[];

  submitBudgetItmObj:submitBudgetItem = new submitBudgetItem();

  cont: number = 0;

  show_new_vendor:boolean = false;

  f_VendorGuid:any = new FormControl('');
  f_input_vendorName:any = new FormControl('');
  f_input_currencyType:any = new FormControl('');

  f_expenseCategoryType:any = new FormControl('');
  f_expenseType:any = new FormControl('');

  f_input_accountInfo:any = new FormControl('');
  f_input_accountNotes:any = new FormControl('');
  f_input_outstandingBalance:any = new FormControl('');

  f_input_amtDueDefault:any = new FormControl('');
  f_input_amtDueConv:any = new FormControl('');

  f_frequencyType:any = new FormControl('');

  f_input_weeklyDueDate:any = new FormControl('');
  f_input_mthDueDate:any = new FormControl('');
  f_input_annualDueDate:any = new FormControl('');

  f_input_frqStartDate:any = new FormControl('');
  f_input_frqEndDate:any = new FormControl('');

  f_inputPaymentSrc:any = new FormControl('');  


  /* errors */
  error_vendor_name:string = "";


  constructor(public bsModalRef: BsModalRef,
    private budgetService:BudgetDataService,
    private userService: UserDataService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {

    // simulate 3 requests with different delays (and catch error)
    this.spinner.show();

    this.cont = 0;

    forkJoin({
      requestVendor: this.budgetService.getBudgetVendor().pipe(catchError(error => of(error)), tap(() => this.cont++) ),
      requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++)),
      currStuff: this.userService.getCurrencies().pipe(catchError(error => of(error)), tap(() => this.cont++)),
      paymentSrc: this.budgetService.getPaymentSourceOptions().pipe(catchError(error => of(error)), tap(() => this.cont++))
    })
    .subscribe(({requestVendor, requestDic, currStuff, paymentSrc}) => {
      this.budgetVendorModel = requestVendor['data'];
      this.dictionaryObj = requestDic['data'];
      this.currencyList = currStuff['data'];
      this.paymentSrcObj = paymentSrc['data'];
      this.spinner.hide();
    })


    // TEST VALUES
    // this.n_current_stage = 5;
    this.f_input_outstandingBalance.setValue('0.00');
    this.f_input_amtDueConv.setValue('0.00');

    this.f_input_frqStartDate.setValue('2023-09-01');
    this.f_input_frqEndDate.setValue('2099-09-01');

    this.f_input_weeklyDueDate.setValue(0)
    this.f_input_mthDueDate.setValue(0)
    this.f_input_annualDueDate.setValue("2099-12-12")
  }


  onBudgetVendorChange(event:any) {
    if (event.target.value == '999-999-999') {
      this.show_new_vendor = true;
    } else {
      this.show_new_vendor = false;
    }

  }



  onSubmit() {

    this.submitBudgetItmObj.vendor_guid = this.f_VendorGuid.value;
    this.submitBudgetItmObj.vendor_name = this.f_input_vendorName.value;
    this.submitBudgetItmObj.currency_code = this.f_input_currencyType.value;

    this.submitBudgetItmObj.category_product_type = +this.f_expenseCategoryType.value;
    this.submitBudgetItmObj.expense_type = +this.f_expenseType.value;

    this.submitBudgetItmObj.account_info = this.f_input_accountInfo.value;
    this.submitBudgetItmObj.account_notes = this.f_input_accountNotes.value;
    this.submitBudgetItmObj.outstanding_balance = this.f_input_outstandingBalance.value;


    this.submitBudgetItmObj.amount_due = this.f_input_amtDueDefault.value;
    this.submitBudgetItmObj.amount_due_conv = this.f_input_amtDueConv.value;

    this.submitBudgetItmObj.frequency_type = +this.f_frequencyType.value;

    this.submitBudgetItmObj.frequency_start_date = this.f_input_frqStartDate.value;
    this.submitBudgetItmObj.frequency_end_date = this.f_input_frqEndDate.value;

    this.submitBudgetItmObj.weekly_due_date = +this.f_input_weeklyDueDate.value;
    this.submitBudgetItmObj.month_due_date = +this.f_input_mthDueDate.value;
    this.submitBudgetItmObj.annual_due_date = this.f_input_annualDueDate.value;

    this.submitBudgetItmObj.annual_month_due = 0;
    this.submitBudgetItmObj.annual_day_due = 0;


    this.submitBudgetItmObj.payment_src = this.f_inputPaymentSrc.value;



    this.budgetService.createBudgetItem(this.submitBudgetItmObj)
              .subscribe(
                  (data:any) => {

                      // console.log('show data');
                      // console.log(data)

                      if (data['status'][0]['msg_status'] != "success") {
                        
                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                      } else {                    
                        
                        alert('Budget Item Created');

                        // this.commonService.notifyOther({option:'addInvoice',value:this.invoice});

                        // _this.contribution_in_progress = false;
                        // _this.contribution_submitted = true;
                        // _this.reset_input();                       
                      }
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });

  }  // createBrand
    



  closeModal(): void {
    console.log('close modal issued');
    return;  
  };

}
