import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import { InvoiceDataService } from '../../_serviceAPI/invoice.data.service';
import { InvoiceApiService } from '../../_serviceAPI/invoice.api.service';

import { IncomeReport } from '../../_model/getIncomeReport';



@Component({
  selector: 'app-mod-report-income',
  templateUrl: './mod-report-income.component.html',
  styleUrls: ['./mod-report-income.component.css']
})
export class ModReportIncomeComponent implements OnInit {

  is_api_active = false;

  incomeReportModel!:IncomeReport[];


  n_grand_total!:string;


  n_date_from = "2021-01-01";
  n_date_to = "2023-08-01"
    
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private invoiceService:InvoiceDataService
  ) { }


  ngAfterViewInit() {
    this.loadIncomeReport();
  }

  ngOnInit(): void {
  }

 

  loadIncomeReport() {

      this.is_api_active = true;

      this.invoiceService.getIncomeReport(this.n_date_from, this.n_date_to)
              .subscribe(
                  (data:any) => {


                      if (data['status'][0]['msg_status'] != "success") {

                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                      } else {                    

                        // Record Data
                        this.incomeReportModel = data['data']['report_data']
                        this.n_grand_total = data['data']['report_data_summary']['grand_total']
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });
  }

}