import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";


@Component({
  selector: 'app-mod-sidemenu',
  templateUrl: './mod-sidemenu.component.html',
  styleUrls: ['./mod-sidemenu.component.css']
})
export class ModSidemenuComponent implements OnInit {

  title = 'side-menu';
  n_url:string = "";
  n_url_filter:string = "";

  constructor(
    private route:ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe((value) => {
        this.n_url = value['menu_option'];
        this.n_url_filter = value['menu_filter'];
      });
  }

}