<section class="vh-100" style="background-color: #fff;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card" style="border-radius: 1rem;border-width:0px;">
          <div class="row g-0">


          	<div class="d-flex align-items-center mb-3 pb-1">
	            <a class="navbar-brand" routerLink="/" style="font-size:28px;font-weight:bold;">

	            	 <span class="logo-icon">
	            	 	<img class ="logo_display" src="../assets/images/logo/logo_32.png" />
		            </span>
		            BankWise.app<span style="font-size:11px;color:#FF0000;">&nbsp;&nbsp;&nbsp; (private beta)</span></a>
	        </div>

            
            <div class="col-md-12 col-lg-12 d-none d-md-block justify-content-center align-items-center" style="padding-top:70px;padding-left:0px;text-align: center;">
              
              <img src="/assets/images/not_found_404.png"
                alt="login form" class="img-responsivex" style="border:0px solid #000;" />
            

            </div>

            <br/>


            <div class="row" style="text-align:center;">
           		 	<div class="col col-xl-12 justify-content-center align-items-center">
           		 		<div class="lrg_title align-items-center">404 Error</div>
            			<div>This page could not be found.  Click <a routerLink="/">here</a> to return to main page</div>
           		 	</div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>

