import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dictionaryfilter',
  pure: true
})
export class DictionaryPipe implements PipeTransform {

  transform(items: any[], n_code_type:number): any {

        // only proceed if items is not empty
        if (items == undefined) {
          // console.log("hey David, items is undefined");
          return;
        }

        if (n_code_type == 700) {
          // decide what item
          // then decide action
          return items.filter(item => item['code_type'] == n_code_type);

        } else {

          return items.filter(item => item['code_type'] == n_code_type);          
        
        }
         
            

    }

}
