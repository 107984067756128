
<div class="container" style="min-height:450px;background-color:#ffffff;">


  <div class="row" style="margin-bottom:30px;">
    <div class="col-12">
      <mat-progress-bar *ngIf="in_progress" mode="indeterminate" ></mat-progress-bar>
    </div>
  </div>



  <div class="row">
    <div class="col-12" style="text-align:right;padding-top:0px;">
      <button class="closez" mat-button (click)="closeDialog()" style="color:#000000;">X</button>
    </div>
  </div>


  <div class="row">
      <div class="col-12 text-center">

        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="#77CE1F" d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"/></svg>

        <br/>

                <div style="margin-top:10px;">
                  <h3 class="sign_up_title">Private Beta</h3>
                </div>

                <p class="sign_up_verbiage">
                Sign up for our private beta and be notified once we open our registration period.
                </p>


                <div class="col-12" style="margin-top:50px;">
                  <mat-form-field appearance="outline" style="width:250px;">
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email Address" [formControl]="email" style="color:#000;background-color:#E0E0E0;text-align:center;padding-top:5px;padding-bottom:5px;">
                       <mat-error>{{error_msg}}</mat-error>
                       <!-- <mat-hint style="color:#ff0000;">{{error_msg}}</mat-hint> -->
                       
                    </mat-form-field>
                </div>

                <div class="col-12">
                  <button type="button" class="btn btn-dark btn-lg" style="width:250px;letter-spacing:2.5px;font-size:14px;" (click)="submitNotify()">SUBSCRIBE</button>
                </div>
                
      </div>
  </div>

</div>
