export class ResourceModel {

  resource_guid!:string;

  first_name!:string;
  last_name!:string;
  display_name!:string;
  organization_name!:string;
  
  role_type_label!:string;
  resource_code_id!:string;
  email!:string;
  rate_per_hour!:string;
  access_code!:string;

  status!:number;
  lastupdate!:string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}