import { Component, OnInit, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { DictionaryPipe } from '../../../../_filters/dictionary.pipe';

import { CommonService } from '../../../../_service/common.service';

import { UserDataService } from '../../../../_serviceAPI/user.data.service';
import { AccountDataService } from '../../../../_serviceAPI/account.data.service';
import { BudgetDataService } from '../../../../_serviceAPI/budget.data.service';

import { Dictionary } from '../../../../_model/dictionary';

import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';

import { AccountSummary } from '../../../../_model/getAccountSummary';

import { PaymentSourceOptions } from '../../../../_model/getPaymentSourceOptions';



@Component({
  selector: 'app-create-payment-source',
  templateUrl: './create-payment-source.component.html',
  styleUrls: ['./create-payment-source.component.css']
})
export class CreatePaymentSourceComponent implements OnInit {

    dictionaryObj!: Dictionary[];
    accountObj!: AccountSummary[];
    paymentSourceOptions:PaymentSourceOptions = new PaymentSourceOptions();

    cont: number = 0;

    show_new_vendor:boolean = false;


    f_paymentType:any = new FormControl('');
    f_paymentSourceName:any = new FormControl('');
    f_accountGuid:any = new FormControl('');


    error_vendor_name:string = "";

    constructor(public bsModalRef: BsModalRef,
      private accountService:AccountDataService,
      private userService: UserDataService,
      private budgetService:BudgetDataService,
      private spinner: NgxSpinnerService,
      private commonService: CommonService
      ) { }

    

      ngOnInit(): void {

          this.spinner.show();

          this.cont = 0;

          forkJoin({
            requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++)),
            accountStuff: this.accountService.getAccountSummary(99).pipe(catchError(error => of(error)), tap(() => this.cont++))
          })
          .subscribe(({requestDic, accountStuff}) => {
            this.dictionaryObj = requestDic['data'];
            this.accountObj = accountStuff['data'];
            this.spinner.hide();
          })

          // TEST VALUES
      }



      onSubmit() {

        this.paymentSourceOptions.payment_type = this.f_paymentType.value;
        this.paymentSourceOptions.payment_source_name = this.f_paymentSourceName.value;
        this.paymentSourceOptions.account_guid = this.f_accountGuid.value;
        
        this.budgetService.createPaymentSourceOption(this.paymentSourceOptions)
                  .subscribe(
                      (data:any) => {

                          if (data['status'][0]['msg_status'] != "success") {                            
                            alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                          } else {
                            alert('Account Created');
                          }
                      },
                      error => {
                          alert("ERROR - " + "a network error occurred");
                      });
      
      }

       
      closeModal(): void {
          console.log('close modal issued');
          return;  
      }

}