<ngx-spinner bdOpacity = 0.9 bdColor = "#fff" size = "medium" color = "#6495ED" type = "ball-spin-clockwise" [fullScreen] = "false"><p style="color:#6495ED;padding-top:120px;" > Loading... </p></ngx-spinner>


<div class="modal-header modal-custom-hdr">
    <div class="modal-title pull-left">Create Outstanding Item for vendor - <span class="lrgTitle">{{name_of_vendor}}</span> {{parent_guid_budget_line}}</div>    
</div>
<div class="modal-body">



	<div class="row" style="margin-top:20px;">
    	
    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=200>
    		  <mat-label>Invoice Number</mat-label>
	          <input matInput placeholder="" [formControl]="f_invNumber" maxlength="100" placeholder="inv #" required>
	          <mat-error *ngIf="f_invNumber.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>

    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=200>
    		  <mat-label>Invoice Date</mat-label>
	          <input matInput placeholder="" [formControl]="f_invDate" maxlength="100" placeholder="YYYY-MM-DD" required>
	          <mat-error *ngIf="f_invDate.invalid">{{error_vendor_name}}</mat-error>
	          <!--
	          <mat-hint>50 Characters</mat-hint>
	          -->
	        </mat-form-field>
    	</div>
    </div>



    <div class="row" style="margin-top:20px;">
    	
    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=150>
    		  <mat-label>Invoice Amount</mat-label>
	          <input matInput placeholder="" [formControl]="f_invAmount" maxlength="100" placeholder="00.00" required>
	          <mat-error *ngIf="f_invAmount.invalid">{{error_vendor_name}}</mat-error>
	        </mat-form-field>
    	</div>

    	<div class="col-4">
    		<mat-form-field appearance="fill" [style.width.px]=250>
    		  <mat-label>Invoice Notes</mat-label>
	          <input matInput placeholder="" [formControl]="f_invNotes" maxlength="500" placeholder="*Notes">
	          <mat-error *ngIf="f_invNotes.invalid">{{error_vendor_name}}</mat-error>
	          <!--
	          <mat-hint>50 Characters</mat-hint>
	          -->
	        </mat-form-field>
    	</div>
    </div>



	<!-- VENDOR -->
    <div class="row" style="margin-top:40px;">
      	<div class="col-3">
    		<mat-form-field>
			  <mat-label>Payment Status</mat-label>
  			  <select matNativeControl required [formControl]="f_paymentStatus">
  			    <option label="-- Payment Status --"></option>
  			    <option value="0" >Outstanding</option>
  			    <option value="1" >Paid</option>
  			    <option value="2" >Agreement Pending</option>
  			  </select>
		    </mat-form-field>
       	</div>
    </div>


	<div class="row">
      <div class="col-12" style="padding-top:25px;font-size:20px;text-align:right;">
        <button type="button" class="btn btn-light" style="color:#909090;" (click)="bsModalRef.hide()">Cancel</button>  &nbsp; &nbsp;
        <button  type="button" class="btn btn-info" (click)="onSubmit()">Next</button>
        <!--
        	<button type="button" class="btn btn-info" (click)="createInvoice()">Next</button>
    	-->
      </div>      
    </div>  	
</div>


