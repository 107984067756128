import { Component, OnInit, NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

/* Material */
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule} from '@angular/material/select';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { CommonService } from '../../../../_service/common.service';

import { InvoiceDataService } from '../../../../_serviceAPI/invoice.data.service';
import { InvoiceApiService } from '../../../../_serviceAPI/invoice.api.service';
import { Invoices } from '../../../../_model/getInvoices';

import { Dictionary } from '../../../../_model/dictionary';

@Component({
  selector: 'app-create-inv',
  templateUrl: './create-inv.component.html',
  styleUrls: ['./create-inv.component.css']
})
export class CreateInvComponent implements OnInit {


  invoice:Invoices = new Invoices();
  
  f_inv_number = new FormControl('');
  f_inv_date = new FormControl('');
  
  f_inv_hours = new FormControl('');
  f_description = new FormControl('');
  f_unit_price = new FormControl('');
  f_inv_amount = new FormControl('');
  f_inv_subtotal = new FormControl('');

  f_can_hst = new FormControl('');
  f_can_gst = new FormControl('');
  f_can_qst = new FormControl('');

  f_sales_tax_subtotal = new FormControl('');
  f_total_payable = new FormControl('');
  f_currency_type = new FormControl('');


  /* errors */
  error_brand_name:string = "";
  error_brand_description:string = "";
  error_brand_status:string = "";

  selected = 'option2';

  constructor(public bsModalRef: BsModalRef,
    private invoiceService:InvoiceDataService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {

  }


  closeModal(): void {
    console.log('close modal issued');
    return;  
  };


  createInvoice():void {

    console.log('aaaaaaaaaaaa :::::::::::');
    this.invoice.company_guid = 'aa5e5cb0-cb54-4364-9bdb-6f104b635ca2';


    console.log('bbbbbbbbbbbb :::::::::::');
    this.invoice.bill_to_guid = '859c21b6-188d-4784-9bbe-9a496d7fd352';
    this.invoice.currency_type = 1

    let myNumber : number = + this.f_inv_number.value;
    this.invoice.invoice_number = myNumber;

    this.invoice.invoice_date = this.f_inv_date.value;
    this.invoice.description = this.f_description.value;

    this.invoice.unit_price = this.f_unit_price.value;
    
    let myHours : number = + this.f_inv_hours.value;
    this.invoice.hours = myHours;
    
    this.invoice.subtotal = this.f_inv_subtotal.value;

    this.invoice.can_hst = this.f_can_hst.value;
    this.invoice.can_gst = this.f_can_gst.value;
    this.invoice.can_qst = this.f_can_qst.value;

    this.invoice.invoice_amount = this.f_inv_subtotal.value;
    this.invoice.sales_tax_subtotal = this.f_sales_tax_subtotal.value;
    this.invoice.total_payable = this.f_total_payable.value;


    this.invoiceService.createInvoice(this.invoice)
              .subscribe(
                  (data:any) => {

                      // console.log('show data');
                      // console.log(data)

                      if (data['status'][0]['msg_status'] != "success") {
                        
                        //_this.contribution_in_progress = false;
                        // alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                      } else {                    

                        // Add to Brand Model HERE
                        // console.log('test1');
                        // this.invoice = data['data']
                        
                        alert('Invoice Added');

                        this.commonService.notifyOther({option:'addInvoice',value:this.invoice});

                        // _this.contribution_in_progress = false;
                        // _this.contribution_submitted = true;
                        // _this.reset_input();                       
                      }
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });

  }  // createBrand




  resetInput():void {
    // this.f_inv_amount.reset();

    this.f_inv_number.reset();
    this.f_inv_date.reset();
    this.f_description.reset();

    this.f_unit_price.reset();
    this.f_inv_hours.reset();
    this.f_inv_subtotal.reset();

    this.f_can_hst.reset();
    this.f_can_gst.reset();
    this.f_can_qst.reset();

    this.f_sales_tax_subtotal.reset();
    this.f_total_payable.reset();

  }

}
