import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { AccountDataService } from '../../_serviceAPI/account.data.service';
import { BudgetDataService } from '../../_serviceAPI/budget.data.service';


import { AccountSummary } from '../../_model/getAccountSummary';
import { PaymentSourceOptions } from '../../_model/getPaymentSourceOptions';

import { CreatePaymentSourceComponent } from '../../main-modules/mod-payment-source/modal/create-payment-source/create-payment-source.component';


@Component({
  selector: 'app-mod-payment-source',
  templateUrl: './mod-payment-source.component.html',
  styleUrls: ['./mod-payment-source.component.css']
})
export class ModPaymentSourceComponent implements OnInit {


    is_api_active = false;

    modalRef?: BsModalRef;

    accountSummaryModel!:AccountSummary[]; 
    paymentSourceOptions!:PaymentSourceOptions[]; 
    
    displayedColumns: string[] = ['payment_source_name', 'payment_type_description', 'internal_user_label'];

    dataSource!: MatTableDataSource<any>;


    constructor(
      private modalService: BsModalService,
      private _liveAnnouncer: LiveAnnouncer,
      private accountService:AccountDataService,
      private budgetService:BudgetDataService
    ) { }

    @ViewChild(MatSort) sort = new MatSort();


    ngAfterViewInit() {
      this.loadAccounts();
    }

    ngOnInit(): void {
    }
   
    announceSortChange(sortState: Sort) {
      if (sortState.direction) {
        this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
        this._liveAnnouncer.announce('Sorting cleared');
      }
    }



    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    

    loadAccounts() {

        console.log('test_one ::::');

        this.is_api_active = true;

        var n_type = 1;

        this.budgetService.getPaymentSourceOptions()
                .subscribe(
                    (data:any) => {

                        console.log('test_two ::::');

                        if (data['status'][0]['msg_status'] != "success") {

                          alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                        } else {                    

                          // Record Data
                          this.paymentSourceOptions = data['data']
                          this.dataSource = new MatTableDataSource(this.paymentSourceOptions);
                          this.dataSource.sort = this.sort;                        
                        }

                        this.is_api_active = false;
                    },
                    error => {
                        alert("ERROR - " + "a network error occurred");
                    });
    }




    openCreateBudgetItemModal() {
      const initialState: ModalOptions = {
        initialState: {
          title: 'Create Modal'
        },
        class: 'modal-dialog-centered modal-lg',
        backdrop: 'static',
        keyboard: true
      };
      this.modalRef = this.modalService.show(CreatePaymentSourceComponent, initialState);

      this.modalRef.content.closeBtnName = 'Close';
    }




}