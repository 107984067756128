import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InvoiceApiService } from './invoice.api.service';

import { Invoices } from '../_model/getInvoices';
import { Observable, observable } from 'rxjs';

@Injectable()
export class InvoiceDataService {

  constructor(
    private http: HttpClient,
    private api: InvoiceApiService    
  ) { }

  getInvoices() {
    return this.api.getInvoices()    
  }

  getIncomeReport(date_from:string, date_to:string) {
    return this.api.getIncomeReport(date_from, date_to)    
  }


  createInvoice(invoice:Invoices): Observable<Invoices> {
    return this.api.createInvoice(invoice)
  }

}