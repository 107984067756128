<ngx-spinner bdOpacity = 0.9 bdColor = "#fff" size = "medium" color = "#6495ED" type = "ball-spin-clockwise" [fullScreen] = "false"><p style="color:#6495ED;padding-top:120px;" > Loading... </p></ngx-spinner>

<div class="modal-header modal-custom-hdr">
    <div class="modal-title pull-left">Select Items for Projected Budget - {{nLabelTitle}}</div>    
</div>
<div class="modal-body">


		<div *ngFor="let t of budgetItemsModel; let i =index" class="row" style="margin-top:20px;" >
			
			<div class="col-4">
				<div class="hdrTextR" [ngClass]="{'hdrSelected': t.contains_selected === true}">{{t.vendor_name}} - {{t.product_category_type_description}} / {{t.expense_type_description}}</div>
			</div>

			<div class="col-4">
				<div class="hdrTextR" [ngClass]="{'hdrSelected': t.contains_selected === true}">{{t.frequency_type_description}} --> {{t.next_payment_date}}</div>
			</div>

			<div class="col-4">
				<div class="hdrTextR" [ngClass]="{'hdrSelected': t.contains_selected === true}">Days Until:{{t.days_until_payment}} --> {{t.amount_due}} {{t.vendor_currency_type}}</div>
			</div>


			<div class="col-3">
				<!--
				<div class="hdrText">Outstanding:{{t.total_outstanding}} {{t.vendor_currency_type}} | Paid: {{t.total_paid}} {{t.vendor_currency_type}}</div>
				-->
			</div>



			<div *ngIf="t.outstanding_details.length > 0">
				
				<div class="row" style="margin-top:10px;">
						<div class="col-2">
				    		<div class="smallOutstandingTitleR">Invoice #</div>
				    	</div>
				    	<div class="col-2">
				    		<div class="smallOutstandingTitleR">Date</div>
				    	</div>
				    	
				    	<div class="col-2">
				    		<div class="smallOutstandingTitleR">Amount</div>
				    	</div>

				    	<div class="col-2">
				    		<div class="smallOutstandingTitleR">Payment Status</div>
				    	</div>

				    	<div class="col-3">
				    		<div class="smallOutstandingTitleR">Notes</div>
				    	</div>
				</div>


				<div class="row" style="margin-top:0px;" *ngFor="let z of t.outstanding_details">
			    		
			    		<div class="col-2">
				    		<div class="outTextR" [ngClass]="{'outSelected': z.is_selected === true}">{{z.invoice_number}}</div>
				    	</div>
				    	<div class="col-2">
				    		<div class="outTextR" [ngClass]="{'outSelected': z.is_selected === true}">{{z.invoice_date}}</div>
				    	</div>
				    	
				    	<div class="col-2">
				    		<div class="outTextR" [ngClass]="{'outSelected': z.is_selected === true}">{{z.invoice_amount}}</div>
				    	</div>

				    	<div class="col-2">
				    		<div class="outTextR" [ngClass]="{'outSelected': z.is_selected === true}">{{z.payment_status_label}}</div>
				    	</div>

				    	<div class="col-3">
				    		<div class="outTextR" [ngClass]="{'outSelected': z.is_selected === true}">{{z.invoice_notes}}</div>
				    	</div>

				    	<div class="col-1">
				    		<div class="outTextR">
				    			<mat-checkbox [checked]="z.is_selected" (change)="update_selection($event.checked, nLabelGuid, z.guid)"></mat-checkbox>
				    		</div>
				    	</div>

				</div>
			</div>	


		</div>
</div>
