
<!--
displayedColumns: string[] = ['company_name', 'invoice_number', 'bill_to_name', 'hours', 'description', 'invoice_amount', 'sales_tax_subtotal', 'total_payable', 'lastupdate', 'editorial'];
-->

<p class="crud_title">Invoices</p>
<br/>
<p class="crud_sub_title">The Invoices which you have</p>

<button type="button" class="btn btn-info btn-sm" (click)="openCreateInvoiceModal()">Add Invoice</button>

<br/>

<hr/>

<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
</mat-form-field>


<!-- Data Table -->
		<div class="col-sm-12 col-md-12 col-lg-12" class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)" class="">
			  <!--- Note that these columns can be defined in any order.
			        The actual rendered columns are set as a property on the row definition" -->


			  <!-- User Label -->
			  <ng-container matColumnDef="company_name">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description"> Company Name </th>
			    <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
			  </ng-container>


			  <!-- Account ID -->
			  <ng-container matColumnDef="invoice_number">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Acc. id"> Inv. # </th>
			    <td mat-cell *matCellDef="let element"> {{element.invoice_number}} </td>
			  </ng-container>


			  <!-- Account ID -->
			  <ng-container matColumnDef="invoice_date">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Acc. id"> Inv. # </th>
			    <td mat-cell *matCellDef="let element"> {{element.invoice_date}} </td>
			  </ng-container>


			  <!-- Account Number -->
			  <ng-container matColumnDef="bill_to_name">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Status Description"> Client </th>
			    <td mat-cell *matCellDef="let element"> {{element.bill_to_name}} </td>
			  </ng-container>


			  <!-- Description -->
			  <ng-container matColumnDef="description">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Status Description"> Description </th>
			    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
			  </ng-container>


			  <!-- Invoice_Amount -->
			  <ng-container matColumnDef="invoice_amount">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Status Description"> Amount</th>
			    <td mat-cell *matCellDef="let element"> {{element.invoice_amount}} </td>
			  </ng-container>


			  <!-- Sales_tax_subtotal -->
			  <ng-container matColumnDef="sales_tax_subtotal">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Status Description"> Taxes</th>
			    <td mat-cell *matCellDef="let element"> {{element.sales_tax_subtotal}} </td>
			  </ng-container>


			  <!-- total payable -->
			  <ng-container matColumnDef="total_payable">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Status Description"> Payable</th>
			    <td mat-cell *matCellDef="let element"> {{element.total_payable}} </td>
			  </ng-container>



			  <!-- last_update -->
			  <ng-container matColumnDef="lastupdate">
			    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Last Update"> Last Update </th>
			    <td mat-cell *matCellDef="let element"> {{element.lastupdate}} </td>
			  </ng-container>




			  <!-- Edit Column -->
			  <ng-container matColumnDef="editorial">
			    <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
			    <td mat-cell *matCellDef="let element">
			     <a href="/main/account-details/{{element.guid}}"> <span  style="color:#5F9EA0;">view details</span></a>
			 	</td>

			  </ng-container>


			  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			  <!-- Empty State -->
			  <!-- Row shown when there is no data. -->
		      <tr class="mat-row" *matNoDataRow>
		          <td *ngIf="is_api_active==true" class="mat-cell" [attr.colspan]="4">
		          	<span>Loading...</span>
		          	<mat-progress-bar mode="indeterminate" class="table-progressbar"></mat-progress-bar>
		          </td>
		          <td *ngIf="is_api_active==false" class="mat-cell" [attr.colspan]="4">
		          	<div style="padding-top:40px">** No data found</div>
		      	  </td>
		      </tr>

			</table>

			<mat-paginator [length]="100"
              [pageSize]="25"
              showFirstLastButtons
              [pageSizeOptions]="[25, 50, 100]">
</mat-paginator>

		</div>
