export class BudgetItems {

  guid!:string;
  user_guid!:string;

  vendor_name!:string;
  vendor_currency_type!:string;
  vendor_currency_description!:string;

  product_category_type!:string;
  product_category_type_description!:string;

  expense_type!:string;
  expense_type_description!:string;

  account_info!:string;
  account_notes!:string;
  outstanding_balance!:number;

  amount_due!:string;
  alt_currency_base!:string;

  frequency_type_description!:string;
  frequency_due_date!:string;

  default_payment_src!:string;

  outstanding_total_rcds!:number;
  outstanding_total_amt!:string;

  next_payment_date!:string;
  days_until_payment!:string;  

  contains_selected!:boolean;

  outstanding_details!: outstanding_details[];


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}





export class outstanding_details {
      guid!: string;
      budget_line_parent_guid!: string;

      invoice_number!: string;
      invoice_date!: string;
      invoice_amount!: string;
      invoice_notes!: string;
      
      payment_status!: boolean;

      payment_status_label!:string;

      alt_currency_base!:string;

      is_selected!:boolean;
 }