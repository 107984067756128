export class Invoices {

  guid!:string;
  user_guid!:string;

  invoice_number!:number;

  invoice_date!:string;

  company_guid!:string;
  bill_to_guid!:string;

  company_name!:string;
  bill_to_name!:string;

  hours!:number;

  description!:string;

  unit_price!:string;
  invoice_amount!:string;
  
  subtotal!:string;

  can_hst!:string;
  can_gst!:string;
  can_qst!:string;

  sales_tax_subtotal!:string;

  total_payable!:string;

  currency_type!: number;

  currency_type_description!:string;

  lastupdate!:string;
  creationdate!:string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}