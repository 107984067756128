import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenManager } from '../_service/token-manager.service';

 
@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(
    	private router: Router,
    	private tokenManager: TokenManager) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    	if(this.tokenManager.isLoggedIn()) {
            console.log('THIS IS LOGGED IN ....');
    		return true;
    	}

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        console.log('THIS SHOULD REDIRECT ....');
        this.router.navigate(['/login']);
        return false;
    }
}