import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';



import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';


import { AccountDataService } from '../../_serviceAPI/account.data.service';
import { AccountApiService } from '../../_serviceAPI/account.api.service';

import { AccountSummary } from '../../_model/getAccountSummary';
import { AccountDetails } from '../../_model/getAccountDetails';





@Component({
  selector: 'app-mod-account-details',
  templateUrl: './mod-account-details.component.html',
  styleUrls: ['./mod-account-details.component.css']
})
export class ModAccountDetailsComponent implements AfterViewInit {



  private sub: any;
  menu_option!: string;
  menu_filter!: string;

  is_api_active = false;

  

  accountSummaryModel!:AccountSummary;
  accountDetailsModel!:AccountDetails[]; 
  
  displayedColumns: string[] = ['unique_id', 'payee', 'transaction_type', 'amount', 'transaction_date', 'ug_payee_label_type_description', 'ug_transaction_status_description', 'editorial'];

  dataSource!: MatTableDataSource<any>;
  
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private accountService:AccountDataService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  @ViewChild(MatSort) sort = new MatSort();


  ngAfterViewInit() {
    this.loadAccountDetails();
  }




  ngOnInit(): void {


    this.sub = this.route.params.subscribe(params => {
           this.menu_option = params['menu_option']; // (+) converts string 'id' to a number
           
           // In a real app: dispatch action to load the details here.
           if (this.menu_option.toLowerCase() == 'account-details') {
              this.menu_filter = params['menu_filter'];
            }
        });
    

      /* check for 36 characters and re-route to 404
      var complete_menu_list = ["account", "account-details"];
      if (complete_menu_list.indexOf(this.menu_option.toLowerCase()) == -1) {
        this.router.navigate(['404']) 
      }
      */



  } //ngOnInit




  /** Announce the change in sort state for assistive technology. */  
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  

  loadAccountDetails() {

      this.is_api_active = true;
      
      var n_account_reference_guid = this.menu_filter;

      this.accountService.getAccountDetails(n_account_reference_guid)
              .subscribe(
                  (data:any) => {

                      if (data['status'][0]['msg_status'] != "success") {

                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                      } else {                    

                        // Record Data
                        this.accountSummaryModel = data['data']["account_header_data"][0];

                        this.accountDetailsModel = data['data']["account_details_data"];
                        this.dataSource = new MatTableDataSource(this.accountDetailsModel);
                        this.dataSource.sort = this.sort;                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });
  }

}