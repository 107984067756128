export class AccountSummary {

  guid!:string;
  user_guid!:string;
  bank_guid!:string;

  bank_credit_inv_asset_type!:number;

  bank_name_label!:string;
  bank_country_label!:string;

  currency_code!:string;

  internal_user_label!:string;
  internal_account_type!:number;
  
  account_id!:string;
  account_number!:string;

  routing_number!:string;
  branch_id!:string;
  swift_code!:string;

  account_type!:string;
  account_type_label!:string;

  institution_organization!:string;
  institution_fid!:string;

  cc_limit!:number;
  cc_ending_in!:string;

  lastupdate!:string;
  creationdate!:string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}