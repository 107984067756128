<p class="crud_title">Dashboard</p>
<br/>
<p class="crud_sub_title">Financial Overview</p>

<br/>

<hr/>


<span>Account Balance</span> <br/>
<span>Account #1 &nbsp; &nbsp; | &nbsp; &nbsp;</span>  <span>balance: $XXXX.XX   (show)</span>
