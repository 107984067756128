import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatDrawerMode, MatSidenavModule, MatSidenav} from '@angular/material/sidenav';

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { TaxAuditDataService } from '../../_serviceAPI/tax_audit.data.service';
import { TaxAuditApiService } from '../../_serviceAPI/tax_audit.api.service';
import { UserDataService } from '../../_serviceAPI/user.data.service';
import { UserApiService } from '../../_serviceAPI/user.api.service';



import { TaxAuditCategories } from '../../_model/getTaxAuditCategories';
import { TaxAuditDetails } from '../../_model/getTaxAuditDetails';


import { DictionaryPipe } from '../../_filters/dictionary.pipe';
import { Dictionary } from '../../_model/dictionary';


import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';

@Component({
  selector: 'app-mod-tax-categories',
  templateUrl: './mod-tax-categories.component.html',
  styleUrls: ['./mod-tax-categories.component.css']
})
export class ModTaxCategoriesComponent implements OnInit {

  mode = new FormControl('over' as MatDrawerMode);

  cont: number = 0;

  f_categoryType:any = new FormControl('');
  f_categoryName:any = new FormControl('');
  f_assignedDate:any = new FormControl('');

  error_vendor_name:string = "";

  @ViewChild('sidenav') sidenav!: MatSidenav;

  is_api_active = false;


  dictionaryObj!: Dictionary[];
  taxCategoriesModel!:TaxAuditCategories[]; 
  
  displayedColumns: string[] = ['category_type', 'category_dictionary_name', 'category_name', 'assigned_date', 'editorial'];

  dataSource!: MatTableDataSource<any>;
  
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private taxService:TaxAuditDataService,
    private userService: UserDataService
  ) { }

  @ViewChild(MatSort) sort = new MatSort();

  ngAfterViewInit() {
    
  }

  ngOnInit(): void {
    this.loadCategories();
  }

  /** Announce the change in sort state for assistive technology. */  
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  

  loadCategories() {

      this.is_api_active = true;

      forkJoin({
        taxCategories: this.taxService.getTaxCategories().pipe(catchError(error => of(error)), tap(() => this.cont++) ),
        requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++))
      })
      .subscribe(({taxCategories, requestDic}) => {
        this.taxCategoriesModel = taxCategories['data']
        this.dataSource = new MatTableDataSource(this.taxCategoriesModel);
        this.dataSource.sort = this.sort;
        this.dictionaryObj = requestDic['data'];
        this.is_api_active = false;
        // this.spinner.hide();
      })        
  }


  createTaxCategory() {

        this.taxService.createTaxAuditCategory(this.f_categoryType.value, this.f_categoryName.value, this.f_assignedDate.value)
              .subscribe(
                  (data:any) => {
                      console.log('test_two ::::');
                      if (data['status'][0]['msg_status'] != "success") {
                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                      } else {
                        // Close Side Bar
                        this.sidenav.toggle();
                        // Refresh Data Source
                        this.loadCategories();                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });

  }




}
