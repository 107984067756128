import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatDrawerMode, MatSidenavModule, MatSidenav} from '@angular/material/sidenav';

import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

import { DictionaryPipe } from '../../_filters/dictionary.pipe';
import { Dictionary } from '../../_model/dictionary';

import { TaxAuditDataService } from '../../_serviceAPI/tax_audit.data.service';
import { TaxAuditApiService } from '../../_serviceAPI/tax_audit.api.service';

import { TaxAuditCategories } from '../../_model/getTaxAuditCategories';
import { TaxAuditDetails } from '../../_model/getTaxAuditDetails';

import { UserDataService } from '../../_serviceAPI/user.data.service';
import { UserApiService } from '../../_serviceAPI/user.api.service';

import { map, delay, catchError, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';


@Component({
  selector: 'app-mod-tax-items',
  templateUrl: './mod-tax-items.component.html',
  styleUrls: ['./mod-tax-items.component.css']
})
export class ModTaxItemsComponent implements OnInit {

  mode = new FormControl('over' as MatDrawerMode);

  error_vendor_name:string = "";

  cont: number = 0;

  f_categoryGuid:any = new FormControl('');
  f_itemType:any = new FormControl('');
  f_itemAmount:any = new FormControl('');
  f_isCredit:any = new FormControl('');
  f_notes:any = new FormControl('');


  @ViewChild('sidenav') sidenav!: MatSidenav;

  is_api_active = false;

  dictionaryObj!: Dictionary[];
  taxCategoriesModel!:TaxAuditCategories[];
  taxDetailsModel!:TaxAuditDetails[]; 
  
  displayedColumns: string[] = ['category_dictionary_name', 'category_name', 'assigned_date', 'item_dictionary_name', 'item_amount', 'notes', 'editorial'];

  dataSource!: MatTableDataSource<any>;
  
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private taxService:TaxAuditDataService,
    private userService: UserDataService
  ) { }

  @ViewChild(MatSort) sort = new MatSort();

  ngAfterViewInit() {  
  }

  ngOnInit(): void {
    this.loadTaxDetails();
  }

  /** Announce the change in sort state for assistive technology. */  
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  loadTaxDetails() {

      this.is_api_active = true;

      forkJoin({
        taxCategories: this.taxService.getTaxCategories().pipe(catchError(error => of(error)), tap(() => this.cont++) ),
        taxDetails: this.taxService.getTaxDetails().pipe(catchError(error => of(error)), tap(() => this.cont++) ),
        requestDic: this.userService.getDictionary().pipe(catchError(error => of(error)), tap(() => this.cont++))
      })
      .subscribe(({taxCategories, taxDetails, requestDic}) => {
        this.taxCategoriesModel = taxCategories['data']
        this.taxDetailsModel = taxDetails['data']
        this.dataSource = new MatTableDataSource(this.taxDetailsModel);
        this.dataSource.sort = this.sort;
        this.dictionaryObj = requestDic['data'];
        this.is_api_active = false;
        // this.spinner.hide();

        this.taxCategoriesModel = this.taxCategoriesModel.sort(this.sortByDicName)
        this.dictionaryObj = this.dictionaryObj.sort(this.sortByName);
      })        
  }



  createTaxItem() {

    console.log("Credit Boolean");
    console.log(this.f_isCredit.value);

    this.taxService.createTaxAuditItem(this.f_categoryGuid.value, this.f_itemType.value, this.f_itemAmount.value, Number(this.f_isCredit.value), this.f_notes.value)
              .subscribe(
                  (data:any) => {
                      if (data['status'][0]['msg_status'] != "success") {
                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );
                      } else {
                        // Close Side Bar
                        this.sidenav.toggle();
                        // Refresh Data Source
                        this.loadTaxDetails();                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });

  }



  sortByName(a: any, b: any) {
      var nameA = a.code_value.toUpperCase(); // ignore upper and lowercase
      var nameB = b.code_value.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
  }



  sortByDicNameX(a: any, b: any) {
      var nameA = a.category_dictionary_name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.category_dictionary_name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
  }



  sortByDicName(a: any, b: any) {
      if (a.category_dictionary_name === b.category_dictionary_name) {
         // Price is only important when cities are the same
         return b.category_name - a.category_name;
      }
      return a.category_dictionary_name > b.category_dictionary_name ? 1 : -1;
  }

  

}