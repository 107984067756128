import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  title = 'home';

  private sub: any;
  menu_option!: string;
  menu_filter!: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {


    this.sub = this.route.params.subscribe(params => {
           this.menu_option = params['menu_option']; // (+) converts string 'id' to a number
           
            // In a real app: dispatch action to load the details here.
            if (this.menu_option.toLowerCase() == 'entries') {
              this.menu_filter = params['menu_filter'];
            }
        });

      var complete_menu_list = ["dashboard", "account", "banks", "credit", "investments", "assets", "payment-source", 
        "budget-items", "budget-item-details", "account-details", "invoices", "report-income", "report-budget",
        "tax-audit-categories", "tax-audit-items", "debt-management", "projected-budget", "projected-budget-details",
        "tax-audit-report-a", "tax-audit-report-002"];

      if (complete_menu_list.indexOf(this.menu_option.toLowerCase()) == -1) {
        this.router.navigate(['404']) 
      }



  } //ngOnInit

}
