
<div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-light">
            <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                
                <app-mod-sidemenu></app-mod-sidemenu>
                
                <!--
                	THIS SHOULD BE AT THE BOTTOM FOR LOG OUT ETC
                <div class="dropdown pb-4">
                    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
                        <span class="d-none d-sm-inline mx-1">loser</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                        <li><a class="dropdown-item" href="#">New project...</a></li>
                        <li><a class="dropdown-item" href="#">Settings</a></li>
                        <li><a class="dropdown-item" href="#">Profile</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">Sign out</a></li>
                    </ul>
                </div>
            	-->

            </div>
        </div>

        
        <div class="col py-3">


            <app-mod-dashboard *ngIf="menu_option=='dashboard'"></app-mod-dashboard>



            <app-mod-debt-management *ngIf="menu_option=='debt-management'"></app-mod-debt-management>

            <app-mod-budget-item *ngIf="menu_option=='budget-items'"></app-mod-budget-item>


            <app-mod-budget-item-details *ngIf="menu_option=='budget-item-details'"></app-mod-budget-item-details>


            <app-mod-invoice *ngIf="menu_option=='invoices'"></app-mod-invoice>

            <app-mod-report-income *ngIf="menu_option=='report-income'"></app-mod-report-income>
                    	
        	<app-mod-bank-transactions *ngIf="menu_option=='transactions'"></app-mod-bank-transactions>

            <app-mod-account *ngIf="menu_option=='account'"></app-mod-account>
            <app-mod-account-details *ngIf="menu_option=='account-details'"></app-mod-account-details>



            <app-mod-banks *ngIf="menu_option=='banks'"></app-mod-banks>
            <app-mod-credit *ngIf="menu_option=='credit'"></app-mod-credit>
            <app-mod-investments *ngIf="menu_option=='investments'"></app-mod-investments>
            <app-mod-assets *ngIf="menu_option=='assets'"></app-mod-assets>


            <app-mod-payment-source *ngIf="menu_option=='payment-source'"></app-mod-payment-source>



            <app-mod-report-budget *ngIf="menu_option=='report-budget'"></app-mod-report-budget>

            <app-mod-tax-categories *ngIf="menu_option=='tax-audit-categories'"></app-mod-tax-categories>
            <app-mod-tax-items *ngIf="menu_option=='tax-audit-items'"></app-mod-tax-items>


            <!--
            <app-mod-projected-budget *ngIf="menu_option=='projected-budget'"></app-mod-projected-budget>
            -->
            <app-mod-budget-projected *ngIf="menu_option=='projected-budget'"></app-mod-budget-projected>
            <app-mod-budget-projected-details *ngIf="menu_option=='projected-budget-details'"></app-mod-budget-projected-details>


            <app-mod-tax-report *ngIf="menu_option=='tax-audit-report-a'"></app-mod-tax-report>
            <app-mod-tax-report002 *ngIf="menu_option=='tax-audit-report-002'"></app-mod-tax-report002>

        </div>
    </div>
</div>

