

<mat-sidenav-container class="basic-container">

    <mat-sidenav-content style="min-height:900px;background-color:#fff;">

      <p class="crud_title">Details</p>
      <br/>
      <p class="crud_sub_title">Tax Items</p>

      <br/>

      <p>
        <button type="button" class="btn btn-secondary btn-sm" (click)="sidenav.toggle()">Add Tax Item</button>
      </p>

      <hr/>




<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
</mat-form-field>


<!-- Data Table -->
    <div class="col-sm-12 col-md-12 col-lg-12" class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)" class="">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->


        <!-- Category_Type -->
        <ng-container matColumnDef="category_dictionary_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Acc. id"> Category Type </th>
          <td mat-cell *matCellDef="let element"> {{element.category_dictionary_name}} </td>
        </ng-container>


        <!-- Category Name -->
        <ng-container matColumnDef="category_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Category Name"> Category Name </th>
          <td mat-cell *matCellDef="let element"> {{element.category_name}} </td>
        </ng-container>



        <!-- Assigned Date -->
        <ng-container matColumnDef="assigned_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Assigned Date"> Assigned Date </th>
          <td mat-cell *matCellDef="let element"> {{element.assigned_date}} </td>
        </ng-container>


        <!-- Item Dic. Name -->
        <ng-container matColumnDef="item_dictionary_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Item Name"> Item Name </th>
          <td mat-cell *matCellDef="let element"> {{element.item_dictionary_name}} </td>
        </ng-container>

        <!-- Item Amount -->
        <ng-container matColumnDef="item_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Item Amount"> Item Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.item_amount}} {{element.is_credit_label}} </td>
        </ng-container>


        <!-- Is Credit Label 
        <ng-container matColumnDef="is_credit_label">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Item Amount"> &nbsp; </th>
          <td mat-cell *matCellDef="let element"> {{element.is_credit_label}} </td>
        </ng-container>
        -->


        <!-- Notes -->
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Notes"> Notes </th>
          <td mat-cell *matCellDef="let element"> {{element.notes}} </td>
        </ng-container>


        <!-- Edit Column -->
        <ng-container matColumnDef="editorial">
          <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
          <td mat-cell *matCellDef="let element">
           <a href="/main/account-details/{{element.guid}}"> <span  style="color:#5F9EA0;">view details</span></a>
        </td>

        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Empty State -->
        <!-- Row shown when there is no data. -->
          <tr class="mat-row" *matNoDataRow>
              <td *ngIf="is_api_active==true" class="mat-cell" [attr.colspan]="4">
                <span>Loading...</span>
                <mat-progress-bar mode="indeterminate" class="table-progressbar"></mat-progress-bar>
              </td>
              <td *ngIf="is_api_active==false" class="mat-cell" [attr.colspan]="4">
                <div style="padding-top:40px">** No data found</div>
              </td>
          </tr>

      </table>

      <mat-paginator [length]="100"
              [pageSize]="25"
              showFirstLastButtons
              [pageSizeOptions]="[25, 50, 100]">
      </mat-paginator>

    </div>


    

    </mat-sidenav-content>



    <mat-sidenav #sidenav [mode]="mode.value || 'over'" opened="false" position="end">
      
       <div style="min-width:450px;">

        <div class="container">
          <div class="row">
            <div class="col-12" style="text-align:right;">
              <button mat-button (click)="sidenav.toggle()">X</button>
            </div>
          </div>
        </div>


        <div class="container" style="margin-top:40px;margin-left:20px;">

            <h1>Create Tax Item</h1>
            
            <br/>
            <br/>

            <div class="row">
              <div class="col-12">
                <mat-form-field [style.width.px]=370>
                  <mat-label>Category Type</mat-label>
                    <select matNativeControl required [formControl]="f_categoryGuid">
                      <option *ngFor="let z of taxCategoriesModel; let i = index" value="{{z.guid}}" >{{z.category_dictionary_name}} {{z.category_name}} {{z.assigned_date}}</option>
                    </select>
                </mat-form-field>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                <mat-form-field>
                  <mat-label>Item Type</mat-label>
                    <select matNativeControl required [formControl]="f_itemType">
                      <option *ngFor="let z of dictionaryObj | dictionaryfilter:1600; let i = index" value="{{z.code_id}}" >{{z.code_value}}</option>
                    </select>
                </mat-form-field>
              </div>
            </div>


            <div class="row" style="margin-top:30px;">
              <div class="col-12">
                  <mat-form-field appearance="fill" [style.width.px]=250>
                    <mat-label>Item Amount</mat-label>
                    <input matInput placeholder="" [formControl]="f_itemAmount" maxlength="50" placeholder=""required>
                    <mat-error *ngIf="f_itemAmount.invalid">{{error_vendor_name}}</mat-error>
                  </mat-form-field>
              </div>
            </div>


            <div class="row" style="margin-top:30px;">
              <div class="col-12">

              	<mat-radio-group aria-label="Select an option" [formControl]="f_isCredit">
				  <mat-radio-button value=0 [checked]="false">Standard</mat-radio-button> &nbsp; &nbsp; &nbsp;
				  <mat-radio-button value=1>Credit</mat-radio-button>
				</mat-radio-group>

              </div>
            </div>


            <div class="row" style="margin-top:30px;">
              <div class="col-12">
                  <mat-form-field appearance="fill" [style.width.px]=250>
                    <mat-label>Notes</mat-label>
                    <input matInput placeholder="" [formControl]="f_notes" maxlength="50" placeholder=""required>
                    <mat-error *ngIf="f_notes.invalid">{{error_vendor_name}}</mat-error>
                  </mat-form-field>
              </div>
            </div>


            <br/>
            <br/>

            <button type="button" class="btn btn-primary btn-sm" (click)="createTaxItem()">Create Category</button>

        </div>
        
      </div>


    </mat-sidenav>



</mat-sidenav-container>