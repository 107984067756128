import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserApiService } from './user.api.service';


@Injectable()
export class UserDataService {

  constructor(
    private http: HttpClient,
    private api: UserApiService    
  ) { }



  userLogin(n_email_address:string, n_password:string) {
    return this.api.userLogin(n_email_address, n_password)
  }

  getDictionary() {
    return this.api.getDictionary()
  }


  getBanks() {
    return this.api.getBanks()
  }


  pre_registration(n_type:string) {
    return this.api.pre_registration(n_type)    
  }


  getCurrencies() {
    return this.api.getCurrencies()    
  }


}