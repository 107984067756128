<p class="crud_title">Tax Report 002</p>
<br/>
<p class="crud_sub_title">Show Grouping By Item Type for Total Balance: ${{ calculation()[0].toFixed(2) }}</p>

<br/>

<hr/>


<div *ngFor="let ndata of taxAuditReport002" style="margin-top:15px;">

	<!--
	<div class="rpt_cat_hdr">{{ndata.type_group_name}} ${{ndata.group_totals}}</div>
	-->

	<div class="row">
		<div class="col-3 rpt_cat_hdr">{{ndata.type_group_name}} | {{ndata.type_group}}</div>
		<div class="col-3 rpt_cat_hdr" style="text-align:right;">${{ndata.group_totals}}</div>
	</div>
	
	<div *ngIf="n_ctl_show_details">
		<div *ngFor="let x of ndata.value_set"class="row">
			<div class="col-2">{{x.assigned_date}}</div>
			<div class="col-3">{{x.item_dictionary_name}}</div>
			<div class="col-2" style="text-align:right;">{{x.item_amount}}
				<span *ngIf="x.is_credit">CR</span>
				<span *ngIf="!x.is_credit">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
			</div>
			<div class="col-3" style="font-size:10px;color:#909090;">
			{{x.category_dictionary_name}} - {{x.category_name}}
			</div>
		</div>
	</div>

	

	
</div>
