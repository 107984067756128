export class OutstandingPayment {

  parent_guid!:string;
  invoice_number!:string;
  invoice_date!:string;
  invoice_amount!:string;
  invoice_notes!:string;
  payment_status!:number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }  
}