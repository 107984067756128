import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';


import { AccountDataService } from '../../_serviceAPI/account.data.service';
import { AccountApiService } from '../../_serviceAPI/account.api.service';

import { AccountSummary } from '../../_model/getAccountSummary';

// import { CreateBudgetItemComponent } from '../../main-modules/mod-budget-item/modal/create-budget-item/create-budget-item.component';
import { CreateAccountComponent } from '../../main-modules/mod-account/modal/create-account/create-account.component';



@Component({
  selector: 'app-mod-account',
  templateUrl: './mod-account.component.html',
  styleUrls: ['./mod-account.component.css']
})
export class ModAccountComponent implements AfterViewInit {

  is_api_active = false;

  modalRef?: BsModalRef;

  accountSummaryModel!:AccountSummary[]; 
  
  displayedColumns: string[] = [ 'bank_name', 'bank_country_code', 'account_currency', 'internal_user_label', 'account_type_label', 'account_id', 'account_number', 'editorial'];

  dataSource!: MatTableDataSource<any>;
  
  constructor(
    private modalService: BsModalService,
    private _liveAnnouncer: LiveAnnouncer,
    private accountService:AccountDataService
  ) { }

  @ViewChild(MatSort) sort = new MatSort();


  ngAfterViewInit() {
    this.loadAccounts();
  }

  ngOnInit(): void {
  }

  /** Announce the change in sort state for assistive technology. */  
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  

  loadAccounts() {

      console.log('test_one ::::');

      this.is_api_active = true;

      var n_type = 1;

      this.accountService.getAccountSummary(n_type)
              .subscribe(
                  (data:any) => {


                      console.log('test_two ::::');

                      if (data['status'][0]['msg_status'] != "success") {

                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

                      } else {                    

                        // Record Data
                        this.accountSummaryModel = data['data']
                        this.dataSource = new MatTableDataSource(this.accountSummaryModel);
                        this.dataSource.sort = this.sort;                        
                      }

                      this.is_api_active = false;
                  },
                  error => {
                      alert("ERROR - " + "a network error occurred");
                  });
  }




  openCreateBudgetItemModal() {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Create Modal'
      },
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
      keyboard: true
    };
    this.modalRef = this.modalService.show(CreateAccountComponent, initialState);

    this.modalRef.content.closeBtnName = 'Close';
  }


}
