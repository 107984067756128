import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import { TokenManager } from '../_serviceAPI/token-manager.service';

import { TokenManager } from '../_service/token-manager.service';

import { Observable, observable } from 'rxjs';

// import { DeviceDetectorService } from 'ngx-device-detector';
// import { Dictionary } from '../_model/dictionary';

import { AccountSummary } from '../_model/getAccountSummary';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;


@Injectable()
export class AccountApiService {

  deviceInfo:string = "";

  headersVendor = this.tokenManager.getTokenVendorString()

  // n_env  = "https://api.streams.cc"
  // n_env  = "http://localhost:8080"

  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) {
      // this.epicFunction()
    }


  /* Pre-Registration */

  public getBankList() {
    {
        return this.http
        .post(API_URL + '/v01/GET/banks', {"appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          // return events.map((e: any) => new Brand(e));
          }),
          catchError(this.handleError)
        )                   
    }    
  }



  



  public getAccountSummary(n_type:number) {
    {
        return this.http
        .post(API_URL + '/v01/GET/account_summary', {"filter_type":n_type, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          // return events.map((e: any) => new Brand(e));
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public getAccountDetails(n_account_reference_guid:string) {
    {
        return this.http
        .post(API_URL + '/v01/GET/account_details', {"account_reference_guid":n_account_reference_guid, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
        .pipe(
          map((res: any) => {
          console.log(res);
          return res;
          // return events.map((e: any) => new Brand(e));
          }),
          catchError(this.handleError)
        )                   
    }    
  }


  public createAccount(ac:AccountSummary): Observable<AccountSummary> {
      {
          return this.http
          .post(API_URL + '/v01/CREATE/account', {"bank_guid":ac.bank_guid,"currency_code":ac.currency_code, 
            "internal_user_label":ac.internal_user_label, "internal_account_type":Number(ac.account_type),
            "account_id":ac.account_id, "account_number":ac.account_number, "routing_number":ac.routing_number,
            "branch_id":ac.branch_id, "appsource":"web"} , { headers: this.tokenManager.getTokenHeaders()})
          .pipe(
            map((res: any) => {
            return res;
            }),
            catchError(this.handleError)
          )                   
      }    
    }


  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}