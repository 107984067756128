<p class="crud_title"><span class="lead_title">Detailed</span> Budget Items</p>
<br/>
<p class="crud_sub_title">Detailed Budget Items for <b>{{nTitle}}</b>&nbsp; &nbsp; <span class="editButton" (click)="openUpdateProjectedModal()">[edit]</span> <br/>
</p>

<br/>

<!--
<button type="button" class="btn btn-info btn-sm" (click)="openCreateBudgetItemModal()">Create Item</button>
-->

<hr/>


<div class="row" style="margin-top:40px;" *ngFor="let t of budgetProjectedDetails">
	
	<div class="col-3">
		<div class="hdrText">{{t.vendor_name}} - {{t.product_category_type_description}} / {{t.expense_type_description}}</div>
	</div>

	<div class="col-2">
		<div class="hdrText">{{t.frequency_type_description}} --> {{t.next_payment_date}}</div>
	</div>

	<div class="col-2">
		<div class="hdrText">Days Until:{{t.days_until_payment}} --> {{t.amount_due}} {{t.vendor_currency_type}}</div>
	</div>


	<div class="col-3">
		
		<div class="hdrText">Outstanding:{{t.total_outstanding}} {{t.vendor_currency_type}} | Paid: {{t.total_paid}} {{t.vendor_currency_type}}</div>
	</div>


	<div class="row" style="margin-top:10px;">
			<div class="col-1">
	    		<div class="smallOutstandingTitle">Invoice #</div>
	    	</div>
	    	<div class="col-1">
	    		<div class="smallOutstandingTitle">Date</div>
	    	</div>
	    	
	    	<div class="col-1">
	    		<div class="smallOutstandingTitle">Amount</div>
	    	</div>

	    	<div class="col-1">
	    		<div class="smallOutstandingTitle">Payment Status</div>
	    	</div>

	    	<div class="col-2">
	    		<div class="smallOutstandingTitle">Notes</div>
	    	</div>
	</div>


	<div class="row" style="margin-top:0px;" *ngFor="let z of t.detail_data">
    		
    		<div class="col-1">
	    		<div class="outText">{{z.invoice_number}}</div>
	    	</div>
	    	<div class="col-1">
	    		<div class="outText">{{z.invoice_date}}</div>
	    	</div>
	    	
	    	<div class="col-1">
	    		<div class="outText">{{z.invoice_amount}} {{t.vendor_currency_type}}</div>
	    	</div>

	    	<div class="col-1">
	    		<div class="outText">{{z.payment_status_label}}</div>
	    	</div>

	    	<div class="col-2">
	    		<div class="outText">{{z.invoice_notes}}</div>
	    	</div>

	    	<!--
	    	<div class="col-2">
	    		<div>
	    			<span>[edit]</span> &nbsp;
	    			<span>[rmv]</span>
	    		</div>	    		
	    	</div>
	    	-->

	</div>	




</div>










